import type { IconProps } from '@hexa-ui/icons';
import { useMatchMedia, useTheme } from '@hexa-ui/theme';
import { useSidebarContext } from 'contexts';
import React from 'react';
import { Container, IconContainer, Label, StyledAnchor, StyledLink } from './ListItem.styles';

type ListItemProps = {
  active?: boolean;
  icon: React.FunctionComponent<IconProps>;
  path: string;
  label: string;
  target?: '_blank' | '_self';
  onPageChange: () => void;
  dataTestId: string;
};

const ListItem = ({
  active,
  icon: Icon,
  path,
  label,
  onPageChange,
  target,
  dataTestId,
}: ListItemProps): JSX.Element => {
  const { isSidebarOpen, setIsSideBarOpen } = useSidebarContext();
  const theme = useTheme();
  const [isMobile] = useMatchMedia(['tablet']);

  const handleClick = () => {
    onPageChange?.();

    if (isMobile) {
      setIsSideBarOpen(false);
    }
  };

  return target ? (
    <StyledAnchor href={path} target={target} className={theme} data-testid={dataTestId}>
      <Container
        className={`${isSidebarOpen ? 'open' : 'collapsed'}`}
        title={label}
        onClick={handleClick}
      >
        <IconContainer>
          <Icon />
        </IconContainer>
        <Label className={isSidebarOpen ? 'open' : 'collapsed'}>{label}</Label>
      </Container>
    </StyledAnchor>
  ) : (
    <StyledLink to={path} className={theme} data-testid={dataTestId}>
      <Container
        className={`${isSidebarOpen ? 'open' : 'collapsed'} ${active ? 'active' : ''}`}
        title={label}
        onClick={handleClick}
      >
        <IconContainer>
          <Icon />
        </IconContainer>
        <Label className={isSidebarOpen ? 'open' : 'collapsed'}>{label}</Label>
      </Container>
    </StyledLink>
  );
};

export default ListItem;
