import { UserMetadata, useUserMetadata } from 'admin-portal-shared-services';
import { UserInfoToken } from 'admin-portal-shared-services/dist/services/authentication/AuthenticationService.types';
import { useAuthContext } from 'contexts';
import { useSegmentAnalytics } from 'hooks';
import { useEffect } from 'react';
import { getUserIds } from 'utils';
import { OriginApp } from '../../../analytics';

const getPartnerAttributes = (data: UserMetadata, decodedToken: UserInfoToken) => {
  if (!decodedToken?.vendorId) return null;
  const vendor = data?.vendors?.find((vendor) => vendor.id === decodedToken.vendorId);
  return vendor ? { name: vendor?.displayName, type: vendor?.serviceModel } : null;
};

export const useAnalyticsLoginSuccessful = () => {
  const { decodedToken } = useAuthContext();
  const { data } = useUserMetadata();
  const segmentAnalytics = useSegmentAnalytics();
  const { anonymousId, userId } = getUserIds();
  const isPartner = decodedToken?.createdOn === 'supplier';
  const partnerAttributes = isPartner ? getPartnerAttributes(data, decodedToken) : null;

  useEffect(() => {
    decodedToken &&
      data &&
      segmentAnalytics?.loginSuccessful({
        anonymousId,
        userId,
        properties: {
          is_federated: decodedToken?.isFederated || false,
          origin_app: OriginApp.AdminPortal,
          is_partner: isPartner,
          partner_name: partnerAttributes?.name || null,
          partner_type: partnerAttributes?.type || null,
        },
      });
  }, [anonymousId, userId, decodedToken, segmentAnalytics, partnerAttributes, data, isPartner]);
};
