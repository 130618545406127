import { Error503 as Error503Page } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export const Error503 = (): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <Error503Page
      headerText={intl.formatMessage({ id: 'Error503.title' })}
      subheaderText={intl.formatMessage({ id: 'Error503.description' })}
      buttonText={intl.formatMessage({ id: 'Error503.button' })}
      infoText={intl.formatMessage(
        { id: 'Error503.infoText' },
        {
          a: (str) => (
            <a
              href="https://ab-inbev.atlassian.net/servicedesk/customer/portal/5"
              target="_blank"
              rel="noreferrer"
            >
              {str}
            </a>
          ),
        }
      )}
      buttonClickHandler={handleClick}
    />
  );
};

export default Error503;
