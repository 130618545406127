/* eslint-disable react-hooks/rules-of-hooks */
import { useAuthenticationService } from 'admin-portal-shared-services';
import Axios from 'axios';
import { v4 } from 'uuid';

export const setupAxios = () => {
  const instance = Axios.create({});
  const authenticationService = useAuthenticationService();
  authenticationService.enhancedAxios(
    instance as unknown as Parameters<typeof authenticationService.enhancedAxios>[0],
    {
      headers: [
        {
          country: authenticationService.getUserCountryAndLanguage().user_country || '',
          requestTraceId: v4(),
        },
      ],
    },
  );
  return instance;
};
