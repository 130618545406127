import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts';

export interface HandleAuthorizationProps {
  permission: string;
  onlyFederatedAccess: boolean;
  element: JSX.Element;
}

export const HandleAuthorization = ({
  permission,
  onlyFederatedAccess,
  element,
}: HandleAuthorizationProps) => {
  const { appsWithScope, decodedToken } = useAuthContext();

  if (onlyFederatedAccess && !decodedToken.isFederated) {
    return <Navigate to="/error/403" />;
  }

  const isAuthorized =
    appsWithScope.filter((item) => item.appScopePrefix === permission).length > 0;
  return isAuthorized ? element : <Navigate to="/error/403" />;
};

export default HandleAuthorization;
