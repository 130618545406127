export const calculateScrollBarSize = () => {
  return window.innerWidth - document.documentElement.clientWidth;
};

const setScrollBarCSSVariable = (scrollbarSize: number) => {
  const root = document.documentElement;
  root.style.setProperty('--scrollbar-size', `${scrollbarSize}px`);
};

export const toggleScrollbar = (shouldHideScrollbar: boolean) => {
  const scrollbarSize = calculateScrollBarSize();
  if (shouldHideScrollbar) {
    document.body.style.overflow = 'hidden';
    if (scrollbarSize > 0) {
      setScrollBarCSSVariable(scrollbarSize);
    }
  } else {
    document.body.style.overflow = 'auto';
    setScrollBarCSSVariable(0);
  }
};
