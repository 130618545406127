import { Breadcrumb as CBreadcrumb } from '@hexa-ui/components';
import { useAppHeader } from 'admin-portal-shared-services';
import { Link } from 'react-router-dom';

const Breadcrumb = () => {
  const [headerConfig] = useAppHeader();

  const { breadcrumbConfig } = headerConfig;

  if (
    !breadcrumbConfig?.items ||
    breadcrumbConfig?.items.length < 1 ||
    !breadcrumbConfig?.homePath
  ) {
    return null;
  }

  return (
    <CBreadcrumb.Root>
      <CBreadcrumb.HomeItem asChild>
        <Link to={breadcrumbConfig.homePath} />
      </CBreadcrumb.HomeItem>
      {breadcrumbConfig.items.map((currentItem) => (
        <CBreadcrumb.Item asChild isCurrentPage={currentItem.isCurrentPage} key={currentItem.path}>
          <Link onClick={currentItem.onClick} to={currentItem.path}>
            {currentItem.label}
          </Link>
        </CBreadcrumb.Item>
      ))}
    </CBreadcrumb.Root>
  );
};

export default Breadcrumb;
