const CouponManagementLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Brand/Hub/AppIcon/CouponManagement</title>
    <g id="Group" transform="translate(8.000000, 8.000000)">
      <g id="Brand/Logo/Partner/Icon" transform="translate(6.000000, 2.000000)">
        <g id="ZZ_Color-Override/01_Primary/BEESBlack" transform="translate(0.000000, -0.000000)">
          <path
            d="M7.77778 18.6667C7.77778 18.0223 8.27524 17.5 8.88889 17.5H11.1111C11.7248 17.5 12.2222 18.0223 12.2222 18.6667C12.2222 19.311 11.7248 19.8333 11.1111 19.8333H8.88889C8.27524 19.8333 7.77778 19.311 7.77778 18.6667Z"
            fill="#141414"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.4444 0H14.3C13.2772 0.0102026 12.3904 0.745173 12.1433 1.78733C11.8789 2.80136 11.0013 3.50447 10 3.50447C8.99871 3.50447 8.12109 2.80136 7.85667 1.78733C7.60962 0.745173 6.72277 0.0102026 5.7 0H5.55556C2.48883 0.00385714 0.00367346 2.61327 0 5.83333V24.5C0 26.433 1.49238 28 3.33333 28H5.7C6.72277 27.9898 7.60962 27.2548 7.85667 26.2127C8.12109 25.1986 8.99871 24.4955 10 24.4955C11.0013 24.4955 11.8789 25.1986 12.1433 26.2127C12.3904 27.2548 13.2772 27.9898 14.3 28H16.6667C18.5076 28 20 26.433 20 24.5V5.83333C19.9963 2.61327 17.5112 0.00385714 14.4444 0ZM16.6667 25.6667L14.2856 25.5932C13.749 23.5577 11.9773 22.1543 9.96592 22.1715C7.95453 22.1887 6.20489 23.6222 5.7 25.6667H3.33333C2.71968 25.6667 2.22222 25.1443 2.22222 24.5V19.8333H4.44445C5.05809 19.8333 5.55556 19.311 5.55556 18.6667C5.55556 18.0223 5.05809 17.5 4.44445 17.5H2.22222V5.83333C2.22222 3.90034 3.71461 2.33333 5.55556 2.33333L5.71445 2.40683C6.24928 4.42903 8.00122 5.82979 10 5.83333C12.0256 5.81524 13.7927 4.38536 14.3256 2.33333H14.4444C16.2854 2.33333 17.7778 3.90034 17.7778 5.83333V17.5H15.5556C14.9419 17.5 14.4444 18.0223 14.4444 18.6667C14.4444 19.311 14.9419 19.8333 15.5556 19.8333H17.7778V24.5C17.7778 25.1443 17.2803 25.6667 16.6667 25.6667Z"
            fill="#141414"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default CouponManagementLogo;
