import { Analytics } from '@segment/analytics-node';
import { useEnvContext } from 'contexts';
import segmentAnalytics, { setTypewriterOptions } from '../../../analytics';

export const useInstantiateSegmentAnalytics = (segmentKey: string) => {
  if (segmentKey) {
    const analytics = new Analytics({ writeKey: segmentKey, maxEventsInBatch: 1 });
    setTypewriterOptions({ analytics });
  }
};

export const useSegmentAnalytics = () => {
  const { segmentKey } = useEnvContext();
  if (segmentKey) return segmentAnalytics;
  return null;
};
