import { Divider } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const ContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  height: '90%',
  width: '100%',
  marginBottom: '0 $2',
  '@tablet': {
    height: '95%',
  },
});

export const ListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'auto',
  overflowX: 'hidden',
  scrollbarWidth: 'none',
  padding: '$2 $2',
  '&::-webkit-scrollbar': {
    display: 'none',
    scrollbarWidth: 'none',
    scrollbarColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '$interfaceSurfaceSecondary',
    borderRadius: '10px',
  },
  '&:hover': {
    scrollbarWidth: 'thin',
  },
  '&:hover::-webkit-scrollbar': {
    display: 'block',
    width: '8px',
  },
});

export const UtilityContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const UtilityItems = styled('div', {
  padding: '$2',
});

export const StyledDivider = styled(Divider, {
  marginBottom: '$2',
});
