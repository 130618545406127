const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export function getDifferenceInMilliseconds(dateNow: Date, datePast: Date) {
  return dateNow.getTime() - datePast.getTime();
}

export const timeSince = (
  date: string,
  language: string,
  formatMessage: (id: unknown, value?: unknown) => string,
): string => {
  const datePast = new Date(date);
  const dateNow = new Date();

  const diff = getDifferenceInMilliseconds(dateNow, datePast);

  if (diff < MINUTE) {
    const seconds = Math.round(diff / SECOND);
    return new Intl.RelativeTimeFormat(language, { style: 'long' }).format(-seconds, 'second');
  }

  if (diff < HOUR) {
    const minutes = Math.round(diff / MINUTE);
    return new Intl.RelativeTimeFormat(language, { style: 'long' }).format(-minutes, 'minute');
  }

  if (diff < DAY) {
    const hours = Math.round(diff / HOUR);
    return new Intl.RelativeTimeFormat(language, { style: 'long' }).format(-hours, 'hour');
  }

  if (diff < 2 * DAY) {
    return formatMessage(
      { id: 'Notification.yesterday' },
      { value: new Intl.DateTimeFormat(language, { hour: '2-digit', minute: 'numeric' }).format() },
    );
  }

  return `${new Intl.DateTimeFormat(language, {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  }).format(datePast)} ${new Intl.DateTimeFormat(language, {
    hour: '2-digit',
    minute: 'numeric',
  }).format(datePast)}`;
};

interface IPageNumber {
  current: number;
}

interface IPagination {
  pageNumber: number;
}

export const validateScrollPagination = (
  isBottom: boolean,
  paginationPageNumber: IPageNumber,
  pagination: IPagination,
): boolean => {
  return isBottom && paginationPageNumber.current !== pagination.pageNumber;
};
