import { Alert } from '@hexa-ui/components';
import React, { useCallback, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { Container } from './Snackbar.styles';

export type SnackbarType = {
  id: string;
  message: string;
  open?: boolean;
  actions?: [firstAction: Action];
};

export type Action = {
  name: string;
  action(): void;
};
interface SnackbarProps {
  message: string;
  id: string;
  open: boolean;
  actions?: [firstAction: Action];
  setSnackbar: React.Dispatch<React.SetStateAction<SnackbarType[]>>;
}

const snackbarListFilter = (notifications, id): SnackbarType[] => {
  const result = notifications.filter((notification) => notification.id !== id);
  return result;
};

export default function Snackbar(props: SnackbarProps): JSX.Element {
  const { id, message, setSnackbar, open, actions } = props;

  const deleteNotification = useCallback(() => {
    setSnackbar((oldSnackbarList: SnackbarType[]) => snackbarListFilter(oldSnackbarList, id));
  }, [id, setSnackbar]);

  const handlers = useSwipeable({
    onSwipedRight: () => deleteNotification(),
    preventScrollOnSwipe: true,
    trackTouch: true,
    trackMouse: true,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      deleteNotification();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [deleteNotification]);

  return (
    <Container data-state={open ? 'open' : 'closed'} {...handlers}>
      <Alert
        message={message}
        componentVariant={'snackbar'}
        data-testid="snackbar"
        actions={actions}
      />
    </Container>
  );
}
