import { ThemeProvider } from '@hexa-ui/theme';
import { HubTheme, useHubThemeState } from 'admin-portal-shared-services';
import { useAuthContext } from 'contexts';

export const OneThemeProvider = ({
  children,
  portalConfigTheme,
}: {
  children: React.ReactNode;
  portalConfigTheme: HubTheme;
}) => {
  const { customization } = useAuthContext();
  const hubTheme = useHubThemeState();

  const applyTheme: HubTheme = hubTheme || customization?.theme || portalConfigTheme || 'customer';

  return <ThemeProvider theme={applyTheme}>{children}</ThemeProvider>;
};
