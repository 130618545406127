import { Button, IconButton } from '@hexa-ui/components';
import { Package } from '@hexa-ui/icons';
import { styled, useMatchMedia } from '@hexa-ui/theme';
import { Button as TButton } from 'admin-portal-shared-services';
import React from 'react';

interface Props extends TButton {
  variant: 'primary' | 'secondary';
}

const HeaderButton = ({ onClick, label, icon, variant }: Props) => {
  const [isMobile] = useMatchMedia(['tablet']);
  const iconComponent = icon() as unknown as React.FC;
  return (
    <>
      {isMobile && (
        <StyledIconButton
          size="small"
          icon={iconComponent ?? Package}
          onClick={onClick}
          data-testid="iconbutton-small"
        />
      )}
      <StyledButton
        variant={variant}
        onClick={onClick}
        icon={iconComponent}
        leading={iconComponent ? true : false}
      >
        {label}
      </StyledButton>
    </>
  );
};

export default HeaderButton;

export const StyledButton = styled(Button, {
  display: 'none !important',
  '@desktopSmall': {
    display: 'flex !important',
  },
});

export const StyledIconButton = styled(IconButton, {
  '@desktopSmall': {
    display: 'none',
  },
});
