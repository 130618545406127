const AntifraudLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
    <title>Brand/Hub/AppIcon/Antifraud</title>
    <g id="Group" transform="translate(13, 12)">
      <path
        fill="#141414"
        fillRule="evenodd"
        d="M17.32 5.242A6.544 6.544 0 004.362 6.551v3.27H3.272A3.272 3.272 0 000 13.095v7.634A3.272 3.272 0 003.272 24H18.54a3.272 3.272 0 003.272-3.272v-7.634a3.272 3.272 0 00-3.272-3.272H6.544V6.55l.005-.223a4.363 4.363 0 018.633-.65 1.09 1.09 0 002.137-.435zm-11.865 6.76h-.002-2.181a1.09 1.09 0 00-1.09 1.092v7.634c0 .602.488 1.09 1.09 1.09H18.54a1.09 1.09 0 001.09-1.09v-7.634a1.09 1.09 0 00-1.09-1.091H5.455z"
        clipRule="evenodd"
      ></path>
    </g>
  </svg>
);

export default AntifraudLogo;
