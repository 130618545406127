import appHeader from 'i18n/messages/appHeader';
import MessageMap from '../i18n.d';

const idID: MessageMap = {
  Components: {
    Sidebar: {
      collapseMenu: 'Menu lipat',
    },
  },
  test: {
    translate: 'Menerjemahkan',
  },
  Error500: {
    title: 'Ups! Terjadi kesalahan',
  },
  Error403: {
    title: 'Hmm... Akses ditolak!',
    description: 'Sepertinya Anda tidak memiliki izin untuk mengakses laman ini.',
    button: 'Beranda',
  },
  Error404: {
    title: 'Hmm... Sepertinya laman ini hilang',
    description: 'Sepertinya laman ini tidak ditemukan atau tidak ada.',
    button: 'Beranda',
  },
  Error503: {
    title: 'Hmm… Layanan tidak tersedia!',
    description:
      'Server kami untuk sementara terlalu sibuk. Anda dapat mencoba lagi dalam beberapa menit, dan laman kemungkinan akan kembali normal.',
    button: 'Coba lagi',
    infoText: 'Apabila masalah masih berlanjut, anda dapat <a>melaporkan masalah ini.</a>',
  },
  AvatarMenu: {
    signOut: 'Keluar',
    userProfile: 'Profil Pengguna',
  },
  Dashboard: {
    pageTitle: 'Beranda',
    errorPage: 'Tidak ada apa-apa di sini',
  },
  Notification: {
    title: 'Pemberitahuan',
    allTab: 'Semua',
    unreadTab: 'Belum dibaca',
    highPriorityTab: 'Prioritas utama',
    markAllRead: 'Tandai semua telah dibaca',
    markUnread: 'Tandai sebagai belum dibaca.',
    markRead: 'Tandai sebagai telah dibaca.',
    highPriority: 'Prioritas utama',
    noNotifications: 'Belum ada pemberitahuan.',
    noUnreadNotifications: 'Tidak ada pemberitahuan yang belum dibaca.',
    noHighPriorityNotifications: 'Belum ada pemberitahuan prioritas utama.',
    noMoreNotifications: 'Tidak ada pemberitahuan lagi untuk ditampilkan.',
    showMore: 'Tampilkan lebih banyak',
    yesterday: 'Kemarin, {value}',
    passwordChanged: 'Kata sandi berhasil diubah!',
  },
  AppSwitcher: {
    yourApps: 'Aplikasi anda',
  },
  AppSwitcherButton: {
    chooseAnApp: 'Pilih aplikasi',
  },
  ApprovalFlow: {
    showApprovalFlowButton: 'Approval flow',
  },
  AppCatalog: {
    showAppCatalogButton: 'Tampilkan katalog aplikasi',
  },
  AdminTool: {
    showAdminToolButton: 'Alat admin BEES One',
  },
  TermsAndConditions: {
    showTermsAndConditionsButton: 'Terms & Conditions',
  },
  PrivacyPolicy: {
    showPrivacyPolicyButton: 'Privacy Policy',
  },
  TermsAndPolicies: {
    titleFirstLogin: 'Syarat & Ketentuan dan Kebijakan Privasi',
    titleUpdate: 'Pembaruan Syarat & Ketentuan dan Kebijakan Privasi',
    descriptionParagraph1:
      'Untuk melanjutkan, konfirmasi bahwa Anda telah membaca dan memahami Syarat dan Ketentuan serta Kebijakan Privasi BEES ONE dengan mencentang kedua kotak di bawah ini.',
    descriptionParagraph2:
      'Kedua dokumen ini dapat diklik, dan ditampilkan dalam berbagai bahasa. Anda selalu dapat memeriksa kembali ketentuan ini dengan bernavigasi ke menu pengaturan.',
    agreeTerms: 'Saya telah membaca dan memahami {link} BEES One.',
    agreePolicies: 'Saya telah membaca dan memahami {link} BEES One.',
    terms: 'Syarat & Ketentuan',
    policies: 'Kebijakan Privasi',
    termsLink: 'Syarat & Ketentuan',
    policiesLink: 'Kebijakan Privasi',
    termsError: 'Anda harus menyetujui ketentuan untuk melanjutkan.',
    policiesError: 'Anda harus menyetujui kebijakan untuk melanjutkan.',
    declineButton: 'Tolak',
    agreeButton: 'Lanjutkan',
  },
  ...appHeader.idID,
};

export default idID;
