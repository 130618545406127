import { keyframes, styled } from '@hexa-ui/theme';
import * as Dialog from '@radix-ui/react-dialog';

export const Container = styled('div', {
  display: 'flex',
  position: 'fixed',
  left: '0',
  flexDirection: 'column',
  zIndex: 9999,
  backgroundColor: '$neutral0',
  overflow: 'auto',
  width: '64px',
  height: '90%',
  alignItems: 'center',
  padding: '$6 $2',
  boxShadow: '$6',
  borderRadius: '$8',
  margin: '$4',
  '&.open': {
    width: '224px',
    borderRadius: '$6',
    alignItems: 'flex-start',
  },
});

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { transform: 'translateX(-100%)' },
  '100%': { transform: 'translateX(0)' },
});

export const Overlay = styled(Dialog.Overlay, {
  background: 'rgba(0 0 0 / 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'grid',
  placeItems: 'center',
  zIndex: '$10',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.0, 0.0, 0.2, 1) forwards`,
  },
});

export const DialogContent = styled(Dialog.Content, {
  minWidth: 300,
  background: 'white',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100vh',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.0, 0.0, 0.2, 1) forwards`,
  },
  '&:focus': { outline: 'none' },
});

export const BackButton = styled('button', {
  all: 'unset',
  display: 'flex',
  padding: '$2 $2 0',
  width: '40px',
  height: '40px',
  justifyContent: 'center',
  alignItems: 'center',
});
