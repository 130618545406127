import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  display: 'flex',
  position: 'fixed',
  flexDirection: 'column',
  overflow: 'hidden',
  left: '0',
  top: '50px',
  backgroundColor: '$neutral0',
  zIndex: '$8',
  width: '64px',
  height: 'calc(95% - 60px)',
  padding: '$6 0',
  boxShadow: '$6',
  borderRadius: '$8',
  margin: '$4',
  transition: 'width 150ms',
  transitionTimingFunction: 'cubic-bezier(0.1, 0.7, 1.0, 0.1)',
  '&.open': {
    width: '224px',
    borderRadius: '$6',
    alignItems: 'flex-start',
  },
});

export const BackButton = styled('button', {
  all: 'unset',
  display: 'flex',
  width: 'auto',
  padding: '0 $2',
  height: '40px',
  justifyContent: 'center',
  margin: '0 $2',
  '&:focus-visible': {
    borderRadius: '2px',
    boxShadow: '0 0 0 2px #000',
  },
  '&.open': {
    padding: 0,
    width: 'calc(100% - 1rem)',
    justifyContent: 'flex-start',
  },
});

export const BackButtonText = styled('span', {
  paddingLeft: '$1',
  color: '$interfaceLabelPrimary',
  fontFamily: '$paragraph',
  fontSize: '$1',
  lineHeight: '$4',
  display: 'none',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineClamp: '1',
  '&.open': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const BackButtonIcon = styled('span', {
  display: 'flex',
  width: '35px',
  height: '40px',
  justifyContent: 'center',
  alignItems: 'center',
});
