import { Error403 as Error403Page } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export const Error403 = (): JSX.Element => {
  const intl = useIntl();

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };

  return (
    <StyledSection>
      <Error403Page
        onButtonClick={handleClick}
        description={intl.formatMessage({ id: 'Error403.description' })}
        header={intl.formatMessage({ id: 'Error403.title' })}
        buttonText={intl.formatMessage({ id: 'Error403.button' })}
      />
    </StyledSection>
  );
};

const StyledSection = styled('section', {
  display: 'flex',
  alignItems: 'center',
  height: '100vh',
});
