import { styled } from '@hexa-ui/theme';
import { NavLink } from 'react-router-dom';

export const StyledLink = styled(NavLink, {
  width: '100%',
  textDecoration: 'none',
  justifyContent: 'center',
  display: 'flex',
  cursor: 'pointer',
});

export const StyledAnchor = styled('a', {
  width: '100%',
  textDecoration: 'none',
  justifyContent: 'center',
  display: 'flex',
  cursor: 'pointer',
});

export const Container = styled('div', {
  display: 'flex',
  width: '100%',
  height: '40px',
  margin: '$1 0',
  padding: '$1 $2',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '20px',
  cursor: 'pointer',
  '&.collapsed': {
    borderRadius: '50%',
    width: '40px',
  },
  '&:hover': {
    backgroundColor: '$brandPrimaryBackground',
  },
  '&.active': {
    backgroundColor: '$brandPrimaryBasis',
  },
  '&.visited': {
    backgroundColor: '$brandPrimaryBasis',
  },
  '&:disabled': {
    color: '$interfaceLabelSecondary',
    pointerEvents: 'none',
  },
});

export const Label = styled('label', {
  display: 'none',
  paddingLeft: '$2',
  color: '$interfaceLabelPrimary',
  fontFamily: '$paragraph',
  fontSize: '$2',
  lineHeight: '$3',
  cursor: 'pointer',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'initial',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  '&.open': {
    display: '-webkit-box;',
  },
  '&:hover': {
    display: '-webkit-box;',
  },
});

export const IconContainer = styled('div', {
  color: '$interfaceLabelPrimary',
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'center',
});
