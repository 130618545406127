import { TypeToast, useToast } from 'admin-portal-shared-services';
import { useLanguageContext } from 'contexts';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

export const usePasswordResetSuccessNotification = () => {
  const toastService = useToast();
  const { formatMessage } = useIntl();
  const { isLoading } = useLanguageContext();
  const changePasswordStatus = localStorage.getItem('notify');

  useEffect(() => {
    if (changePasswordStatus === 'PASSWORD_UPDATED' && !isLoading) {
      toastService.notify({
        type: TypeToast.SUCCESS,
        message: formatMessage({
          id: 'Notification.passwordChanged',
        }),
      });
      localStorage.removeItem('notify');
    }
  }, [changePasswordStatus, toastService, formatMessage, isLoading]);
};

export default usePasswordResetSuccessNotification;
