import { Select } from '@hexa-ui/components';
import { Vendor, appHeaderConfig, useAppHeader } from 'admin-portal-shared-services';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

function formatVendorName(vendor: Vendor) {
  return [vendor.abiVendorId, vendor.displayName].filter(Boolean).join(' - ');
}

const VendorSelect = () => {
  const [headerStore] = useAppHeader();
  const { formatMessage } = useIntl();
  const { onChangeSelectedVendor } = appHeaderConfig;

  const { vendorSelect, vendorOptions, loadingVendors, selectedVendor } = headerStore;

  const vendorSelectDisabled = vendorOptions?.length === 0 || loadingVendors;

  const normalizedVendorOptions = useMemo(() => {
    return vendorOptions
      ?.map((vendor) => ({ id: vendor.id, name: formatVendorName(vendor) }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [vendorOptions]);

  if (!vendorSelect) return null;

  return (
    <Select.Root
      size="small"
      shape="pill"
      width="max-content"
      disabled={vendorSelectDisabled}
      value={selectedVendor}
      onChange={(e) => {
        onChangeSelectedVendor(e);
      }}
      placeholder={
        loadingVendors
          ? formatMessage({ id: 'inner.header.select.vendor.placeholder.loading' })
          : vendorSelectDisabled
          ? formatMessage({ id: 'inner.header.select.vendor.placeholder.disabled' })
          : formatMessage({ id: 'inner.header.select.vendor.placeholder' })
      }
    >
      {normalizedVendorOptions?.map((vendor) => {
        return (
          <Select.Option key={vendor.id} value={vendor.id}>
            {vendor.name}
          </Select.Option>
        );
      })}
    </Select.Root>
  );
};

export default VendorSelect;
