/* eslint-disable @typescript-eslint/no-explicit-any */
import { NOTIFICATIONS_ENDPOINT } from '../../../constants';
import Api from '../Api';

export const loadNotifications = (
  unread = false,
  priority = false,
  pageNumber?: number,
  pageSize?: number,
): Promise<unknown> => {
  let params = '';
  if (unread) params = `read=false`;
  if (priority) params = params.concat(`priority=${priority}`);
  if (pageSize) params = params.concat(`&page=${pageNumber}&pageSize=${pageSize}`);
  let url = `${NOTIFICATIONS_ENDPOINT}${params.length ? `?${params}` : ''}`;
  return Api.get({ url });
};

export const unreadNotification = (notificationId: string): Promise<unknown> => {
  return Api.patch({
    url: `${NOTIFICATIONS_ENDPOINT}/${notificationId}/unread`,
  });
};

export const readNotification = (notificationIds: Array<string>): Promise<unknown> => {
  return Api.patch({
    url: `${NOTIFICATIONS_ENDPOINT}/read`,
    body: notificationIds,
  });
};
