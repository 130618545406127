const BeesSyncLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Brand/Hub/AppIcon/Care</title>
    <defs>
      <path
        d="M6.12236499,16.1666551 L16.8951789,16.166716 C14.7758288,18.3905207 12.6974547,20.5536989 11.8762003,21.4077279 C11.6747209,21.6172462 11.3426098,21.6172462 11.1411202,21.4077279 C10.3205358,20.554379 8.23996924,18.3886125 6.12236499,16.1666551 Z M11.6331055,2.18778136 C11.569505,2.26906332 11.4477851,2.26906332 11.3842252,2.18778136 C8.28349284,-1.77640945 0.903825394,-0.141553884 0,5.38264568 L23.0175439,5.38416819 C22.1118813,-0.159286101 14.7229976,-1.76264592 11.6331055,2.18778136 Z M22.9473052,8.07802381 L0.059378058,8.07802381 C0.614973979,10.6224193 2.10790076,11.9277926 3.56660142,13.4727589 L19.4351692,13.4727589 C20.9137336,11.9067514 22.3789405,10.6808737 22.9473052,8.07802381 Z"
        id="bees-care-path-1"
      ></path>
    </defs>
    <g id="Brand/Hub/AppIcon/Care" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(8.000000, 9.000000)">
        <g id="ZZ_Color-Override/01_Primary/BEESBlack" transform="translate(4.491228, 5.192982)">
          <mask id="bees-care-mask-2" fill="white">
            <use xlinkHref="#bees-care-path-1"></use>
          </mask>
          <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref="#bees-care-path-1"></use>
          <g id="Group" mask="url(#bees-care-mask-2)">
            <g transform="translate(-0.025710, -0.858222)" id="01_Color-Override/04_Neutral/100">
              <rect
                id="Rectangle"
                fill="#000000"
                x="0"
                y="0"
                width="23.1422175"
                height="23.1422175"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BeesSyncLogo;
