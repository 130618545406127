const CategoryAdminLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Brand/Hub/AppIcon/CategoryAdmin</title>
    <defs>
      <path
        d="M7,0 L4,0 C1.790861,0 0,1.790861 0,4 L0,7 C0,9.209139 1.790861,11 4,11 L7,11 C9.209139,11 11,9.209139 11,7 L11,4 C11,1.790861 9.209139,0 7,0 Z M9,7 C9,8.1045695 8.1045695,9 7,9 L4,9 C2.8954305,9 2,8.1045695 2,7 L2,4 C2,2.8954305 2.8954305,2 4,2 L7,2 C8.1045695,2 9,2.8954305 9,4 L9,7 Z M20,0 L17,0 C14.790861,0 13,1.790861 13,4 L13,7 C13,9.209139 14.790861,11 17,11 L20,11 C22.209139,11 24,9.209139 24,7 L24,4 C24,1.790861 22.209139,0 20,0 Z M22,7 C22,8.1045695 21.1045695,9 20,9 L17,9 C15.8954305,9 15,8.1045695 15,7 L15,4 C15,2.8954305 15.8954305,2 17,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,7 Z M7,13 L4,13 C1.790861,13 0,14.790861 0,17 L0,20 C0,22.209139 1.790861,24 4,24 L7,24 C9.209139,24 11,22.209139 11,20 L11,17 C11,14.790861 9.209139,13 7,13 Z M9,20 C9,21.1045695 8.1045695,22 7,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,17 C2,15.8954305 2.8954305,15 4,15 L7,15 C8.1045695,15 9,15.8954305 9,17 L9,20 Z M20,13 L17,13 C14.790861,13 13,14.790861 13,17 L13,20 C13,22.209139 14.790861,24 17,24 L20,24 C22.209139,24 24,22.209139 24,20 L24,17 C24,14.790861 22.209139,13 20,13 Z M22,20 C22,21.1045695 21.1045695,22 20,22 L17,22 C15.8954305,22 15,21.1045695 15,20 L15,17 C15,15.8954305 15.8954305,15 17,15 L20,15 C21.1045695,15 22,15.8954305 22,17 L22,20 Z"
        id="category-path-1"
      ></path>
    </defs>
    <g
      id="Brand/Hub/AppIcon/CategoryAdmin"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group" transform="translate(8.000000, 8.000000)">
        <g
          id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
          transform="translate(4.000000, 4.000000)"
        >
          <mask id="category-mask-2" fill="white">
            <use xlinkHref="#category-path-1"></use>
          </mask>
          <g id="Mask" fillRule="nonzero"></g>
          <g id="Group" mask="url(#category-mask-2)" fillOpacity="0.9">
            <g
              transform="translate(-4.000000, -4.000000)"
              id="01_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
            >
              <rect id="Rectangle" fill="#000000" x="0" y="0" width="32" height="32"></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CategoryAdminLogo;
