import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ANONYMOUS_ID_KEY } from '../../constants';

export function RedirectToActivation() {
  const [searchParams] = useSearchParams();
  const activateRoute = `${window.location.origin}/auth/activate`;

  useEffect(() => {
    const anonymousId = localStorage.getItem(ANONYMOUS_ID_KEY);

    if (anonymousId) searchParams.set('state', anonymousId);

    const finalUrl = searchParams.toString().length
      ? `${activateRoute}?${searchParams.toString()}`
      : activateRoute;

    location.replace(finalUrl);
  }, [activateRoute, searchParams]);
  return null;
}

export default RedirectToActivation;
