import { useSearchParams } from 'react-router-dom';
import { ANONYMOUS_ID_KEY } from '../../constants';

export const useNavigateLogin = () => {
  const [searchParams] = useSearchParams();
  const loginRoute = `${window.location.origin}/auth/login`;

  return () => {
    const anonymousId = localStorage.getItem(ANONYMOUS_ID_KEY);

    if (anonymousId) searchParams.set('state', anonymousId);

    const finalUrl = searchParams.toString().length
      ? `${loginRoute}?${searchParams.toString()}`
      : loginRoute;

    location.replace(finalUrl);
  };
};

export default useNavigateLogin;
