import { useParams } from 'react-router-dom';
import { Error403, Error404, Error500, Error503 } from './components';

type ErrorCode = '403' | '404' | '500' | '503';

export function ErrorPage() {
  const { errorCode } = useParams();
  return <ErrorSwitcher errorCode={errorCode as ErrorCode} />;
}

export default ErrorPage;

export function ErrorSwitcher({ errorCode }: { errorCode: ErrorCode }): JSX.Element {
  switch (errorCode) {
    case '403':
      return <Error403 />;
    case '404':
      return <Error404 />;
    case '500':
      return <Error500 />;
    case '503':
      return <Error503 />;
    default:
      return <Error500 />;
  }
}
