import { Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { AppMenuItem, useAnalyticsMenuNavigation } from 'hooks';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { MenuInteraction } from '../../../analytics';
import { AppCard } from '../AppCard';

export interface AppSwitcherProps {
  handleClose: () => void;
  apps: AppMenuItem[];
  openerEl: HTMLElement;
}

const AppSwitcher = ({ handleClose, apps, openerEl }: AppSwitcherProps): JSX.Element => {
  const analyticsMenuNavigation = useAnalyticsMenuNavigation();
  const appSwitcherContainerRef = React.useRef<HTMLDivElement>();

  function handleElementFocus(focusableElements: HTMLElement[], e: KeyboardEvent) {
    const currentFocusIndex = focusableElements.indexOf(document.activeElement as HTMLElement);
    let nextIndex = 0;

    const focusPreviousElement = () => {
      e.preventDefault();
      nextIndex = currentFocusIndex > 0 ? currentFocusIndex - 1 : focusableElements.length - 1;
      focusableElements[nextIndex].focus();
    };

    const focusNextElement = () => {
      e.preventDefault();
      nextIndex = currentFocusIndex + 1 < focusableElements.length ? currentFocusIndex + 1 : 0;
      focusableElements[nextIndex].focus();
    };

    return {
      focusNextElement,
      focusPreviousElement,
    };
  }

  const handleClickOutside = useCallback(
    (e: Event) => {
      const appSwitcherEl = appSwitcherContainerRef.current;
      const isNotAppSwitcherContent =
        !appSwitcherEl.contains(e.target as Node) && e.target !== appSwitcherEl;
      const isNotAppSwitcherButton = e.target !== openerEl && !openerEl.contains(e.target as Node);

      if (isNotAppSwitcherContent && isNotAppSwitcherButton) {
        handleClose();
      }
    },
    [handleClose, openerEl],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    function handleInputFocusTransfer(e: KeyboardEvent) {
      const [...focusableElements] = document.querySelectorAll(
        "a[data-parent='app-switcher']",
      ) as unknown as HTMLElement[];
      const { focusNextElement, focusPreviousElement } = handleElementFocus(focusableElements, e);
      const lowercaseKey = e.key.toLowerCase();
      const focusByKey = {
        arrowleft: focusPreviousElement,
        arrowright: focusNextElement,
        escape: handleClose,
        tab: focusNextElement,
        shiftTab: focusPreviousElement,
      };

      // returning if it's not a mapped key
      if (!focusByKey[lowercaseKey]) return;

      if (e.shiftKey && lowercaseKey === 'tab') focusByKey.shiftTab();
      else focusByKey[lowercaseKey]();
    }

    document.addEventListener('keydown', handleInputFocusTransfer);

    return () => document.removeEventListener('keydown', handleInputFocusTransfer);
  }, [handleClose]);

  const onChangeRoute = (appNameAnalytics: string): void => {
    analyticsMenuNavigation.track(appNameAnalytics, MenuInteraction.AppSwitcher);
    handleClose();
  };

  return (
    <>
      <Overlay data-testid="appSwitcher-overlay" role="presentation" onClick={handleClose} />
      <Container ref={appSwitcherContainerRef} css={{ top: '56px', width: '100%' }}>
        <Title>
          <FormattedMessage id="AppSwitcher.yourApps" />
        </Title>
        <AppsSection>
          <GridContainer type="fluid" style={{ margin: 0 }}>
            {apps.map((app, index) => (
              <Grid.Item xs={6} sm={4} md={2} key={app.appId}>
                <AppCard
                  iconId={app.appId}
                  appName={app.appName}
                  iconBackgroundColor={app.appIconBackgroundColor}
                  appDescription={app.appDescription}
                  homeRoute={app.homeRoute}
                  baseRoute={app.baseRoute}
                  isFirstEl={index === 0}
                  onChangeRoute={() => onChangeRoute(app.appNameAnalytics)}
                />
              </Grid.Item>
            ))}
          </GridContainer>
        </AppsSection>
      </Container>
    </>
  );
};

export default AppSwitcher;

const GridContainer = styled(Grid.Container, {
  rowGap: '$6',
  width: '100%',
});

const Overlay = styled('div', {
  backgroundColor: '$neutral100',
  op: 'opacityMedium',
  position: 'absolute',
  overflow: 'hidden',
  zIndex: '$10',
  top: '0',
  left: '0',
  height: '100vh',
  width: '100%',
});

const Title = styled('div', {
  color: '$interfaceLabelPrimary',
  fontFamily: '$heading',
  fontSize: '$6',
  fontWeight: '$semibold',
  lineHeight: '$7',
  marginBottom: '$6',
});

const Container = styled('div', {
  position: 'absolute',
  overflow: 'auto',
  width: '100%',
  top: '0',
  right: '0',
  maxHeight: 'calc(100vh - 64px)',
  maxWidth: '100%',
  backgroundColor: '$neutral0',
  borderRadius: '0 0 24px 24px',
  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.16)',
  zIndex: '$12',
  left: 'auto',
  boxSizing: 'border-box',
  padding: '$6',
});

const AppsSection = styled('div', {
  borderRadius: '0 0 24px 24px',
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  rowGap: '$6',
  columnGap: '$4',
});
