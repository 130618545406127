const PricingAdminLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Brand/Hub/AppIcon/PricingAdmin</title>
    <defs>
      <path
        d="M7,0 C7.51283584,0 7.93550716,0.38604019 7.99327227,0.883378875 L8,1 L8,4 L12,4 C12.5522847,4 13,4.44771525 13,5 C13,5.51283584 12.6139598,5.93550716 12.1166211,5.99327227 L12,6 L8,6 L8,11 L9.5,11 C11.9852813,11 14,13.0147187 14,15.5 C14,17.9142733 12.0987635,19.8844891 9.7118357,19.995102 L9.5,20 L8,20 L8,23 C8,23.5522847 7.55228475,24 7,24 C6.48716416,24 6.06449284,23.6139598 6.00672773,23.1166211 L6,23 L6,20 L1,20 C0.44771525,20 0,19.5522847 0,19 C0,18.4871642 0.38604019,18.0644928 0.883378875,18.0067277 L1,18 L6,18 L6,13 L4.5,13 C2.01471863,13 0,10.9852814 0,8.5 C0,6.08572667 1.90123652,4.11551091 4.2881643,4.00489799 L4.5,4 L6,4 L6,1 C6,0.44771525 6.44771525,0 7,0 Z M8,18 L9.5,18 C10.8807118,18 12,16.8807118 12,15.5 C12,14.1745166 10.968464,13.0899613 9.66437568,13.0053177 L9.5,13 L8,13 L8,18 Z M6,11 L6,6 L4.5,6 C3.11928813,6 2,7.11928813 2,8.5 C2,9.8254834 3.03153594,10.9100387 4.33562431,10.9946823 L4.5,11 L6,11 Z"
        id="pricing-path-1"
      ></path>
    </defs>
    <g
      id="Brand/Hub/AppIcon/PricingAdmin"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="02_Icons/dollar-sign" transform="translate(8.000000, 8.000000)">
        <g id="ZZ_Color-Override/Primary/Black" transform="translate(9.000000, 4.000000)">
          <mask id="pricing-mask-2" fill="white">
            <use xlinkHref="#pricing-path-1"></use>
          </mask>
          <g id="Mask" fillRule="nonzero"></g>
          <g id="Group" mask="url(#pricing-mask-2)" fillOpacity="0.9">
            <g
              transform="translate(-9.000000, -4.000000)"
              id="01_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
            >
              <rect id="Rectangle" fill="#000000" x="0" y="0" width="32" height="32"></rect>
            </g>
          </g>
        </g>
        <g id="ZZ_Color-Override/Primary/Black"></g>
      </g>
    </g>
  </svg>
);

export default PricingAdminLogo;
