import AntifraudLogo from './AntifraudLogo';
import ApprovalFlowLogo from './ApprovalFlowLogo';
import AuditLogLogo from './AuditLogLogo';
import AvailabilityLogo from './AvailabilityLogo';
import BeesAiLogo from './BeesAiLogo';
import BeesApiaryLogo from './BeesApiary';
import BeesCmsLogoLogo from './BeesCmsLogo';
import BeesDataPortalLogo from './BeesDataPortalLogo';
import BeesForceLogo from './BeesForceLogo';
import BeesGrowLogo from './BeesGrowLogo';
import BeesGrowPortalLogo from './BeesGrowPortalLogo';
import BeesLinkAdminLogo from './BeesLinkAdminLogo';
import BeesMoneyCombLogo from './BeesMoneyCombLogo';
import BeesSyncLogo from './BeesSyncLogo';
import BeesVendoLogo from './BeesVendoLogo';
import CareLogo from './CareLogo';
import CategoryAdminLogo from './CategoryAdminLogo';
import CompanyManagementLogo from './CompanyManagementLogo';
import CouponManagementLogo from './CouponManagementLogo';
import CourierManagementLogo from './CourierManagement';
import CreditManagementLogo from './CreditManagementLogo';
import DataBrewLogo from './DataBrewLogo';
import DcManagementLogo from './DcManagementLogo';
import DeliveryWindowLogo from './DeliveryWindowLogo';
import DigitalCouponsLogo from './DigitalCouponsLogo';
import EngageLogo from './EngageLogo';
import ExperimentationPlatformLogo from './ExperimentationPlatformLogo';
import GenericDcManagementLogo from './GenericDcManagementLogo';
import GenericLogo from './GenericLogo';
import IconEyeLogo from './IconEyeLogo';
import IdentityManagementLogo from './IdentityManagementLogo';
import InventoryAdminLogo from './InventoryAdminLogo';
import MembershipLogo from './MembershipLogo';
import MinegocioAudienceMgmtLogo from './MinegocioAudienceMgmtLogo';
import MinegocioCompetitionAdminToolAppLogo from './MinegocioCompetitionAdminToolAppLogo';
import MinegocioMonitoringLogo from './MinegocioMonitoringLogo';
import PartnerAdvertisingLogo from './PartnerAdvertisingLogo';
import PartnerBillingLogo from './PartnerBillingLogo';
import PartnerDcManagementLogo from './PartnerDcManagementLogo';
import PartnerInventoryLogo from './PartnerInventoryLogo';
import PartnerLogo from './PartnerLogo';
import PartnerOrdersLogo from './PartnerOrdersLogo';
import PartnerReportsLogo from './PartnerReportsLogo';
import PartnerSettingsLogo from './PartnerSettingsLogo';
import PimLogo from './PimLogo';
import PricingAdminLogo from './PricingAdminLogo';
import PromotionsLogo from './PromotionsLogo';
import SaasToolsBecksLogo from './SaasToolsBecksLogo';
import SaasToolsSwitchbrewLogo from './SaasToolsSwitchbrewLogo';
import SellerManagementLogo from './SellerManagementLogo';
import SocialLogo from './SocialLogo';

export const iconById = {
  BEES_HUB_APPROVAL_FLOW: ApprovalFlowLogo,
  COURIERMANAGEMENT: CourierManagementLogo,
  BEESMONEYCOMB: BeesMoneyCombLogo,
  BEESOFFER: BeesMoneyCombLogo,
  COMPANYMANAGEMENT: CompanyManagementLogo,
  WAREHOUSEMANAGEMENT: DcManagementLogo,
  PIM: PimLogo,
  HUB_AI_TOOL_CHAT: BeesAiLogo,
  PROMOTIONS: PromotionsLogo,
  MEMBERSHIP: MembershipLogo,
  BEESAPIARY: BeesApiaryLogo,
  BEESGROW: BeesGrowLogo,
  BEESVENDO: BeesVendoLogo,
  BEES_LINK_ADMIN: BeesLinkAdminLogo,
  BEES_MODERN_TRADE_MANAGEMENT_SYSTEM: BeesLinkAdminLogo,
  BEES_COMMERCIAL_AGREEMENTS: BeesLinkAdminLogo,
  BEES_MODERN_TRADE_CUSTOMER_LIST: BeesLinkAdminLogo,
  BEES_CREDIT_Management: CreditManagementLogo,
  BEES_KA_INFORMATION_CENTER: BeesLinkAdminLogo,
  BEES_POC_SEGMENTATION: BeesLinkAdminLogo,
  BEES_CHAIN_MANAGEMENT_ORDERS: BeesLinkAdminLogo,
  BEESGROWPORTAL: BeesGrowPortalLogo,
  'MI-NEGOCIO-ADMIN': MinegocioAudienceMgmtLogo,
  'MI-NEGOCIO-ADMIN-APP': MinegocioMonitoringLogo,
  'MI-NEGOCIO-COMPETITION-ADMIN-TOOL-APP': MinegocioCompetitionAdminToolAppLogo,
  IDENTITYMANAGEMENT: IdentityManagementLogo,
  COMPANYSETTINGS: CompanyManagementLogo,
  BROWSECATEGORY: CategoryAdminLogo,
  'SAASTOOLS-BECKS': SaasToolsBecksLogo,
  'SAASTOOLS-SWITCHBREW': SaasToolsSwitchbrewLogo,
  INVITATION: IdentityManagementLogo,
  USERS: IdentityManagementLogo,
  BEES_SYNC: BeesSyncLogo,
  DELIVERY_WINDOW: DeliveryWindowLogo,
  AVAILABILITY: AvailabilityLogo,
  'DATA-BREW': DataBrewLogo,
  CMS: BeesCmsLogoLogo,
  BEESCARE: CareLogo,
  AUDITLOGS: AuditLogLogo,
  BEESENGAGE: EngageLogo,
  'BEESENGAGE-HANDLER': EngageLogo,
  'INVENTORY-ADMIN': InventoryAdminLogo,
  'PRICE-ADMIN': PricingAdminLogo,
  'PARTNER-SETTINGS': PartnerSettingsLogo,
  'PARTNER-ADVERTISEMENT': PartnerAdvertisingLogo,
  'ADVERTISING-MANAGEMENT': PartnerAdvertisingLogo,
  'PARTNER-ADVERTISEMENTTABLE': PartnerAdvertisingLogo,
  'PARTNER-ORDERMANAGEMENT': PartnerOrdersLogo,
  'PARTNER-INVENTORY': PartnerInventoryLogo,
  'PARTNER-INVOICES': PartnerBillingLogo,
  'PARTNER-REPORTS': PartnerReportsLogo,
  'PARTNER-WAREHOUSEMANAGEMENT': PartnerDcManagementLogo,
  INSIGHTS: PartnerReportsLogo,
  BILLING: PartnerBillingLogo,
  'WAREHOUSEMANAGEMENT-HANDLER': GenericDcManagementLogo,
  GENERIC: GenericLogo,
  SOCIALMEDIA: SocialLogo,
  PARTNER_HOME: PartnerLogo,
  BEESDATAPORTAL: BeesDataPortalLogo,
  BEES_FORCE_ADMIN: BeesForceLogo,
  CAMPAIGNS: BeesLinkAdminLogo,
  DEALS: BeesLinkAdminLogo,
  DIGITALCOUPONS: DigitalCouponsLogo,
  SELLER_MANAGEMENT: SellerManagementLogo,
  COUPON_MANAGEMENT: CouponManagementLogo,
  BEES_VISION: IconEyeLogo,
  ANTIFRAUD: AntifraudLogo,
  EXPERIMENTATION_PLATFORM: ExperimentationPlatformLogo,
};
