import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ANONYMOUS_ID_KEY } from '../../constants';

export function RedirectToFederatedLoginPage() {
  const [searchParams] = useSearchParams();
  const loginRoute = `${window.location.origin}/auth/wp`;

  useEffect(() => {
    const anonymousId = localStorage.getItem(ANONYMOUS_ID_KEY);

    if (anonymousId) searchParams.set('state', anonymousId);

    const finalUrl = searchParams.toString().length
      ? `${loginRoute}?${searchParams.toString()}`
      : loginRoute;

    location.replace(finalUrl);
  }, [loginRoute, searchParams]);
  return null;
}

export default RedirectToFederatedLoginPage;
