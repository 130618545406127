import { Error404 as Error404Page } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export const Error404 = (): JSX.Element => {
  const intl = useIntl();

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };

  return (
    <StyledSection>
      <Error404Page
        buttonClickHandler={handleClick}
        buttonText={intl.formatMessage({ id: 'Error404.button' })}
        headerText={intl.formatMessage({ id: 'Error404.title' })}
        subHeaderText={intl.formatMessage({ id: 'Error404.description' })}
      />
    </StyledSection>
  );
};

const StyledSection = styled('section', {
  display: 'flex',
  alignItems: 'center',
  height: '100vh',
});

export default Error404;
