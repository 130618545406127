import { styled } from '@hexa-ui/theme';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useGenerateAvatarMenu } from '../../../../hooks';

export function AvatarMenu() {
  const avatarMenuItems = useGenerateAvatarMenu();

  return (
    <DropdownMenu.Portal>
      <StyledDropdownContent align="end">
        {avatarMenuItems.map((item) => {
          const key = item.id;
          return (
            <StyledDropdownItem key={key} id={item.id} onClick={item.onClick}>
              {item.text}
            </StyledDropdownItem>
          );
        })}
      </StyledDropdownContent>
    </DropdownMenu.Portal>
  );
}

const StyledDropdownItem = styled(DropdownMenu.Item, {
  padding: '1rem 2rem',
  fontFamily: '$paragraph',
  lineHeight: '1.5rem',
  fontWeight: '$medium',
  color: '$interfaceLabelPrimary',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$brandAccentNeutralBackground',
    outline: 'none',
  },
});

const StyledDropdownContent = styled(DropdownMenu.Content, {
  background: '#fff',
  zIndex: '$10',
  borderRadius: '$2',
  boxShadow: '$4',
  overflow: 'hidden',
  padding: '1rem 0',
  minWidth: '16rem',
});
