import { Avatar } from '@hexa-ui/components';
import { AppGrid, User, XCircle } from '@hexa-ui/icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { iconById } from 'assets/apps_icons';
import { AppMenuItem } from 'hooks';
import React, { Suspense, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { getCurrentLogo } from 'utils/logo-utils/logoUtils';
import { useAuthContext, useEnvContext } from '../../contexts';
import { toggleScrollbar } from '../../utils';
import AppSwitcher from '../AppSwitcher/AppSwitcher';
import { AvatarMenu } from './components';

import { themes } from '@hexa-ui/theme';
import Notification from '../Notification/Notification';
import {
  AppName,
  StyledAppSwitcher,
  StyledAvatarButton,
  StyledChevronDown,
  StyledChevronUp,
  StyledContainer,
  StyledHeader,
  StyledIconButton,
  StyledIconElContainer,
  StyledLink,
  StyledLogo,
  StyledTopBarNav,
} from './TopBarNav.styles';

export const TopBarNav = ({ apps }: { apps: AppMenuItem[] }): JSX.Element => {
  const [isSwitcherOpen, setIsSwitcherOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const appSwitcherDesktopButtonRef = useRef<HTMLButtonElement>(null);
  const appSwitcherMobileButtonRef = useRef<HTMLButtonElement>(null);
  const appsWithScope = useMemo(() => apps.filter((app) => !app.hidden), [apps]);
  const isFirstRender = useRef(true);
  const { customization } = useAuthContext();
  const envContextValues = useEnvContext();

  const logo = getCurrentLogo(envContextValues.defaultLogo);

  const currentApp: AppMenuItem | undefined = useMemo(
    () =>
      apps.find(
        (app: AppMenuItem) => `/${app.homeRoute.split('/')[1]}` === `/${pathname.split('/')[1]}`,
      ),
    [pathname, apps],
  );

  const getCurrentAppName = () => {
    if (currentApp?.appId === 'ACCOUNT')
      return <FormattedMessage id="AppSwitcherButton.chooseAnApp" />;
    if (apps.length === 1) return apps[0].appName;
    if (currentApp) return currentApp.appName;

    return <FormattedMessage id="AppSwitcherButton.chooseAnApp" />;
  };

  const AppIcon = iconById[currentApp?.appId] || iconById.GENERIC;

  const handleSwitcherToggle = () => {
    setIsSwitcherOpen((prev) => !prev);
  };

  const handleCloseSwitcher = useCallback(() => {
    setIsSwitcherOpen(false);
    appSwitcherDesktopButtonRef?.current?.focus();
    appSwitcherMobileButtonRef?.current?.focus();
  }, []);

  React.useLayoutEffect(() => {
    // this is to prevent the margin to be applied in the first render
    // of the switcher
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    toggleScrollbar(isSwitcherOpen);
  }, [isSwitcherOpen]);

  return (
    <>
      {isSwitcherOpen && (
        <AppSwitcher
          handleClose={handleCloseSwitcher}
          apps={appsWithScope}
          openerEl={appSwitcherDesktopButtonRef.current}
        />
      )}
      <StyledTopBarNav>
        <StyledHeader>
          <StyledContainer>
            <StyledLink
              to="/"
              aria-label="home link"
              variantLogo={envContextValues.defaultLogo || 'beesOne'}
            >
              {customization?.logo ? (
                <StyledLogo
                  src={customization.logo}
                  alt={`Bees ${customization.theme}`}
                  data-testid="customization-logo"
                />
              ) : (
                <StyledLogo src={logo.src} alt={logo.alt} />
              )}
            </StyledLink>
            <StyledAppSwitcher
              shouldRender={appsWithScope.length >= 1}
              tabIndex={0}
              ref={appSwitcherDesktopButtonRef}
              onClick={handleSwitcherToggle}
              aria-label="desktop-app-switcher-button"
            >
              <StyledIconElContainer
                className={
                  themes?.[customization?.theme || envContextValues.defaultTheme] || 'customer'
                }
                css={{
                  backgroundColor: currentApp?.appIconBackgroundColor || '$brandPrimaryBasis',
                }}
              >
                <AppIcon />
              </StyledIconElContainer>
              <AppName>{getCurrentAppName()}</AppName>
              {isSwitcherOpen ? (
                <StyledChevronUp size="medium" />
              ) : (
                <StyledChevronDown size="medium" />
              )}
            </StyledAppSwitcher>
          </StyledContainer>
          <StyledContainer>
            <StyledIconButton
              onClick={handleSwitcherToggle}
              shouldRender={appsWithScope.length >= 1}
              data-testid="mobile-app-switcher-button"
              ref={appSwitcherMobileButtonRef}
            >
              {isSwitcherOpen ? <XCircle /> : <AppGrid />}
            </StyledIconButton>
            <Suspense fallback="">
              <Notification />
            </Suspense>
            <DropdownMenu.Root onOpenChange={toggleScrollbar}>
              <DropdownMenu.Trigger asChild>
                <StyledAvatarButton aria-controls="topBar-menu" aria-haspopup="true">
                  <Avatar background="primary" size="xlarge" variantType="image">
                    <User size="large" />
                  </Avatar>
                </StyledAvatarButton>
              </DropdownMenu.Trigger>
              <AvatarMenu />
            </DropdownMenu.Root>
          </StyledContainer>
        </StyledHeader>
      </StyledTopBarNav>
    </>
  );
};
