import { Button, Grid, Heading } from '@hexa-ui/components';
import { ArrowRight } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import { DocumentTitle } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { AppMenuItem } from '../../../../hooks';
import GridView from '../GridView';

interface DashboardViewProps {
  appsWithScope: Array<AppMenuItem>;
  hasAccessToAppCatalog: boolean;
}

const DashboardView = ({
  appsWithScope,
  hasAccessToAppCatalog,
}: DashboardViewProps): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledContainer>
      <DocumentTitle>Dashboard</DocumentTitle>
      <StyledRow>
        <StyledTypography size="H2">
          {intl.formatMessage({ id: 'Dashboard.pageTitle' })}
        </StyledTypography>
        {hasAccessToAppCatalog && (
          <StyledLink to="/tool-library">
            <Button
              variant="secondary"
              size="large"
              trailing
              icon={ArrowRight}
              data-testid="appCatalogButton"
            >
              {intl.formatMessage({ id: 'AppCatalog.showAppCatalogButton' })}
            </Button>
          </StyledLink>
        )}
      </StyledRow>
      <GridView apps={appsWithScope} />
    </StyledContainer>
  );
};

export default DashboardView;

const StyledContainer = styled(Grid.Container, {
  width: '100% !important',
  padding: '16px',
  '@tablet': {
    padding: '24px 60px',
  },
});

const StyledTypography = styled(Heading, {
  display: 'none',
  '@desktopSmall': {
    display: 'unset',
  },
});

const StyledRow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  margin: '48px 0px',
});

const StyledLink = styled(Link, {
  textDecoration: 'none',
});
