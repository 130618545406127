const BeesVendoLogo = () => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="49" height="49" rx="24.5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.476 22.283C36.492 22.3542 36.5 22.427 36.5 22.5V23.5C36.5008 24.4736 36.1451 25.4138 35.5 26.143V31.5C35.4967 34.26 33.26 36.4966 30.5 36.4999H27.5131C27.5087 36.5 27.5044 36.5 27.5 36.5C27.4956 36.5 27.4913 36.5 27.4869 36.4999H21.5131C21.5087 36.5 21.5044 36.5 21.5 36.5C21.4956 36.5 21.4913 36.5 21.4869 36.4999H18.5C15.7399 36.4966 13.5033 34.26 13.5 31.5V26.143C12.8549 25.4138 12.4992 24.4736 12.5 23.5V22.5C12.5 22.427 12.508 22.3542 12.524 22.283L13.828 16.4151C14.3255 14.1211 16.3607 12.4883 18.708 12.5001H30.292C32.6408 12.4864 34.6782 14.1196 35.176 16.4151L36.476 22.283ZM22.5 34.4999H26.5V32C26.5 31.4728 26.0921 31.0409 25.5746 31.0027L25.5 31H23.5C22.9728 31 22.5409 31.4079 22.5027 31.9254L22.5 32V34.4999ZM28.5 34.4999V32L28.4979 31.8875C28.4388 30.2828 27.1192 29 25.5 29H23.5L23.3875 29.0021C21.7828 29.0612 20.5 30.3808 20.5 32V34.4999H18.5C16.8431 34.4999 15.5 33.1568 15.5 31.5V27.373C15.8266 27.4575 16.1626 27.5002 16.5 27.5H17.5C18.6487 27.5006 19.742 27.0061 20.5 26.143C21.258 27.0061 22.3513 27.5006 23.5 27.5H25.5C26.6487 27.5006 27.742 27.0061 28.5 26.143C29.258 27.0061 30.3513 27.5006 31.5 27.5H32.5C32.8374 27.5002 33.1734 27.4575 33.5 27.373V31.5C33.5 33.1568 32.1569 34.4999 30.5 34.4999H28.5ZM15.78 16.8491L14.5 22.609V23.5C14.5 24.6046 15.3954 25.5 16.5 25.5H17.5C18.6046 25.5 19.5 24.6046 19.5 23.5C19.5 22.9477 19.9477 22.5 20.5 22.5C21.0523 22.5 21.5 22.9477 21.5 23.5C21.5 24.6046 22.3954 25.5 23.5 25.5H25.5C26.6046 25.5 27.5 24.6046 27.5 23.5C27.5 22.9477 27.9477 22.5 28.5 22.5C29.0523 22.5 29.5 22.9477 29.5 23.5C29.5 24.6046 30.3954 25.5 31.5 25.5H32.5C33.6046 25.5 34.5 24.6046 34.5 23.5V22.609L33.22 16.8491C32.9211 15.4729 31.7002 14.4935 30.292 14.5001H29.5V17.5C29.5 18.0523 29.0523 18.5 28.5 18.5C27.9477 18.5 27.5 18.0523 27.5 17.5V14.5001H21.5V17.5C21.5 18.0523 21.0523 18.5 20.5 18.5C19.9477 18.5 19.5 18.0523 19.5 17.5V14.5001H18.708C17.2998 14.4935 16.0789 15.4729 15.78 16.8491Z"
      fill="#141414"
    />
  </svg>
);

export default BeesVendoLogo;
