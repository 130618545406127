import { globalCss } from '@hexa-ui/theme';
import { TOPBAR_HEIGHT } from '../../constants';

export const globalStyles = globalCss({
  '*': {
    boxSizing: 'border-box',
  },

  'body, html': {
    padding: 0,
    margin: 0,
    backgroundColor: '#fafafa',
    maxWidth: '100%',
    scrollbarColor: '#c4c0c0 #ffff',
  },

  body: {
    width: '100vw',
    alignItems: 'center',
    marginRight: 'var(--scrollbar-size) !important',
  },

  '#mfe-content:empty': {
    display: 'none',
  },

  '#mfe-content': {
    flexGrow: 1,
    width: '100%',
    transition: 'margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    backgroundColor: '#fafafa',
    marginLeft: 0,
    marginTop: `calc(${TOPBAR_HEIGHT} * 2)`,
    height: `calc(100vh - ${TOPBAR_HEIGHT} * 2)`,
    '@desktopSmall': {
      marginTop: `${TOPBAR_HEIGHT}`,
      height: `calc(100vh - ${TOPBAR_HEIGHT})`,
    },
  },
});
