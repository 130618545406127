/* eslint-disable react-hooks/rules-of-hooks */
import { useLogService } from 'admin-portal-shared-services';
import { updatePaginationDone } from '../../../store/content/NotificationContentEvents';
import { IPagination } from '../../../store/content/NotificationItem';

export const execute = (pagination: IPagination): void => {
  const log = useLogService();
  const resetValue = { ...pagination, pageNumber: 0 };
  updatePaginationDone(resetValue);
  log.info('ResetPaginationService - reset pagination');
};
