import { useMatchMedia } from '@hexa-ui/theme';
import { useSidebarContext } from 'contexts';
import { useEffect } from 'react';
import SidebarDesktop from './components/SidebarDesktop/SidebarDesktop';
import SidebarMobile from './components/SidebarMobile/SidebarMobile';

export const Sidebar = () => {
  const [isMobile] = useMatchMedia(['tablet']);
  const { setIsSideBarOpen } = useSidebarContext();

  useEffect(() => () => setIsSideBarOpen(false), [setIsSideBarOpen]);
  return isMobile ? <SidebarMobile /> : <SidebarDesktop />;
};

export default Sidebar;
