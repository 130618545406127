import { useAuthenticationService } from 'admin-portal-shared-services';
import { useCurrentApp } from 'hooks/useCurrentApp';
import { useSegmentAnalytics } from 'hooks/useSegmentAnalytics';
import { getUserIds } from 'utils';
import { ElementLocationType, ElementType } from '../../../analytics';

type TrackProps = {
  isCoreEvent: boolean;
  elementLocationType: ElementLocationType;
  elementLocationName: string;
  elementLabel: string;
  elementName: string;
  elementType: ElementType;
};

export const useAnalyticsElementClicked = () => {
  const segmentAnalytics = useSegmentAnalytics();
  const currentUrl = window.location.href;
  const currentApp = useCurrentApp();
  const authenticationService = useAuthenticationService();

  const track = ({
    isCoreEvent,
    elementLocationType,
    elementLocationName,
    elementLabel,
    elementName,
    elementType,
  }: TrackProps) => {
    const { anonymousId, userId } = getUserIds();

    segmentAnalytics?.elementClicked({
      anonymousId,
      userId,
      properties: {
        app_display_name: currentApp?.name,
        app_id: currentApp?.identifier,
        is_core_event: isCoreEvent,
        page_url: currentUrl,
        user_country: authenticationService.getCountryB2C(),
        user_email: authenticationService.getUserEmailB2C(),
        element_location_type: elementLocationType,
        element_location_name: elementLocationName,
        element_label: elementLabel,
        element_name: elementName,
        element_type: elementType,
        manufacturer_country: null,
        manufacturer_display_name: null,
        manufacturer_id: null,
        manufacturer_service_model: null,
        manufacturer_tier_name: null,
        page_label: null,
        page_name: null,
        user_permission_group: null,
        vendor_country: null,
        vendor_display_name: null,
        vendor_id: null,
        vendor_service_model: null,
        vendor_tier_name: null,
        view_level: null,
      },
    });
  };

  return { track };
};
