import type { IconProps } from '@hexa-ui/icons';
import { useSidebarState } from 'admin-portal-shared-services';
import { useSidebarContext } from 'contexts';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import ListItem from '../ListItem';
import {
  ContentContainer,
  ListContainer,
  StyledDivider,
  UtilityContainer,
  UtilityItems,
} from './Content.styles';

type TreeRouteNode = {
  [key: string]: TreeRouteNode | Record<string, TreeRouteNode>;
};

const Content = () => {
  const { isSidebarOpen } = useSidebarContext();
  const { items, utils } = useSidebarState();
  const location = useLocation();

  /**  
   Get the base path of tree from one route
  example for route user/products/edit/1 for tree: { '/users': { '/products': {} } }
  result: '/users/products'
  **/
  const getBasePathOfTree = (path: string): string => {
    const pathSplited = splitPathBySlash(path);
    let actualTreeNode = routesTree || {};
    let nodeIndex = 0;
    let basePathOfTree = '';
    let foundBasePathInTree = false;
    while (!foundBasePathInTree && nodeIndex < pathSplited.length) {
      const keyToSeachInNode = '/' + pathSplited[nodeIndex];
      if (actualTreeNode[keyToSeachInNode]) {
        basePathOfTree += keyToSeachInNode;
        actualTreeNode = actualTreeNode[keyToSeachInNode];
        nodeIndex++;
      } else {
        foundBasePathInTree = true;
      }
    }
    return basePathOfTree;
  };

  /**  
    Generation of tree from menu routes. The tree node is one or more texts from routes beetween '/'
    example:
      routes:  /users      /users/products     /account
      tree generated: { '/users': { '/products': {} }, '/account': {} }
  **/
  const getRoutesTree = useCallback(
    (treeRouteNode: TreeRouteNode, pathSplited: string[], index: number) => {
      if (index >= pathSplited.length) return treeRouteNode;
      const keyToSeachInNode = '/' + pathSplited[index];

      if (!treeRouteNode[keyToSeachInNode]) {
        const newTreeNode = { ...treeRouteNode, [keyToSeachInNode]: {} };
        newTreeNode[keyToSeachInNode] = getRoutesTree({}, pathSplited, index + 1);
        return newTreeNode;
      } else {
        return {
          ...treeRouteNode,
          [keyToSeachInNode]: getRoutesTree(
            treeRouteNode[keyToSeachInNode],
            pathSplited,
            index + 1,
          ),
        };
      }
    },
    [],
  );

  const splitPathBySlash = (path: string): string[] =>
    path.split('/').filter((pathSplit) => pathSplit);

  const routesTree: TreeRouteNode = useMemo(() => {
    let treeRoutes: TreeRouteNode = {};
    if (items.length > 0) {
      items.forEach(({ path }) => {
        treeRoutes = getRoutesTree(treeRoutes, splitPathBySlash(path), 0);
      });
    }
    if (utils.length > 0) {
      utils.forEach(({ path }) => {
        treeRoutes = getRoutesTree(treeRoutes, splitPathBySlash(path), 0);
      });
    }
    return treeRoutes;
  }, [getRoutesTree, items, utils]);

  const activeItem = (path: string): boolean => {
    const baseRouteLocationPath = getBasePathOfTree(location.pathname);
    return baseRouteLocationPath === path;
  };

  return (
    <ContentContainer>
      <ListContainer className={isSidebarOpen ? 'open' : null}>
        {items.length > 0 &&
          items.map(({ id, title, icon, path, onPageChange }) => (
            <ListItem
              key={id}
              icon={icon as React.FunctionComponent<IconProps>}
              label={title}
              path={path}
              active={activeItem(path)}
              onPageChange={onPageChange}
              dataTestId="sidenav-item"
            />
          ))}
      </ListContainer>
      {utils.length > 0 && (
        <UtilityContainer>
          <StyledDivider />
          <UtilityItems>
            {utils.map(({ id, title, icon, path, onPageChange, target }) => (
              <ListItem
                key={id}
                icon={icon as React.FunctionComponent<IconProps>}
                label={title}
                path={path}
                target={target}
                active={activeItem(path)}
                onPageChange={onPageChange}
                dataTestId="sidenav-util"
              />
            ))}
          </UtilityItems>
        </UtilityContainer>
      )}
    </ContentContainer>
  );
};

export default Content;
