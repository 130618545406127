import { styled } from '@hexa-ui/theme';
import { useIntl } from 'react-intl';
import BeesLogo from '../../images/BUZZ_Error_AdminHome.svg';

const EmptyView = (): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledContainer>
      <StyledLogo src={BeesLogo} alt="BUZZ_EmptyView_AdminHome" />
      <StyledText data-testid="emptyPageText">
        {intl.formatMessage({ id: 'Dashboard.errorPage' })}
      </StyledText>
    </StyledContainer>
  );
};

export default EmptyView;

const StyledContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledLogo = styled('img', {
  width: '90px',
  height: '108px',
  marginTop: '71.5px',
  '@desktopLarge': {
    marginTop: '99.5px',
  },
});

const StyledText = styled('p', { fontFamily: '$paragraph', fontSize: '16px' });
