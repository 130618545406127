import { keyframes, styled } from '@hexa-ui/theme';

const VIEWPORT_PADDING = 25;

const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: 'translateX(0)' },
});

export const Container = styled('div', {
  borderRadius: 6,
  padding: '0px 0px 15px 0px',
  display: 'grid',
  gridTemplateAreas: '"title action" "description action"',
  gridTemplateColumns: 'auto max-content',
  alignItems: 'center',
  justifyContent: 'end',

  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in forwards`,
    },
  },
});

export const Viewport = styled('div', {
  position: 'fixed',
  top: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  width: 390,
  maxWidth: '100vw',
  margin: '72px 16px 0',
  zIndex: 2147483647,
  '@tablet': {
    margin: '80px 24px 0 0',
  },
});
