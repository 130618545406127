import { useAppHeader } from 'admin-portal-shared-services';
import DropdownButton from '../DropdownButton/DropdownButton';
import HeaderButton from '../HeaderButton/HeaderButton';

const OptionalButtons = () => {
  const [headerConfig] = useAppHeader();

  const { optionalButtons } = headerConfig;

  if (!optionalButtons || optionalButtons.length < 1) return null;

  return (
    <>
      {optionalButtons.map((button, index) => {
        if (button.options?.length > 0) {
          return (
            <DropdownButton
              {...button}
              key={button.label}
              variant={index === 0 ? 'primary' : 'secondary'}
            />
          );
        }
        return (
          <HeaderButton
            {...button}
            key={button.label}
            variant={index === 0 ? 'primary' : 'secondary'}
          />
        );
      })}
    </>
  );
};

export default OptionalButtons;
