const MinegocioMonitoringLogo = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" />
    <path
      d="M22.6668 33.2667C18.3602 32.96 16.0002 31.2667 16.0002 30.6667V27.6933C17.5068 28.4267 19.3335 28.92 21.3335 29.16C21.3335 28.28 21.3868 27.3867 21.6135 26.52C19.3335 26.2267 17.2935 25.5467 16.0002 24.6V20.8533C17.9068 21.9333 20.6668 22.6267 23.7602 22.6667C23.8002 22.6267 23.8268 22.5733 23.8668 22.5333C26.8002 19.6133 31.3335 19.2133 34.6668 21.3733V17.3333C34.6668 14.3867 29.8935 12 24.0002 12C18.1068 12 13.3335 14.3867 13.3335 17.3333V30.6667C13.3335 33.6133 18.1202 36 24.0002 36C24.4535 36 24.9068 36 25.3335 35.96C24.8268 35.6267 24.3202 35.2533 23.8668 34.8C23.4002 34.32 23.0002 33.8133 22.6668 33.2667ZM24.0002 14.6667C29.1602 14.6667 32.0002 16.6667 32.0002 17.3333C32.0002 18 29.1602 20 24.0002 20C18.8402 20 16.0002 18 16.0002 17.3333C16.0002 16.6667 18.8402 14.6667 24.0002 14.6667ZM35.0802 31.8667C35.6668 30.9467 36.0002 29.84 36.0002 28.6667C36.0002 25.3333 33.3335 22.6667 30.0002 22.6667C26.6668 22.6667 24.0002 25.3333 24.0002 28.6667C24.0002 32 26.6668 34.6667 30.0002 34.6667C31.1602 34.6667 32.2535 34.3333 33.1735 33.76L37.3335 37.8533L39.1868 36L35.0802 31.8667ZM30.0002 32C29.1161 32 28.2683 31.6488 27.6431 31.0237C27.018 30.3986 26.6668 29.5507 26.6668 28.6667C26.6668 27.7826 27.018 26.9348 27.6431 26.3096C28.2683 25.6845 29.1161 25.3333 30.0002 25.3333C30.8842 25.3333 31.7321 25.6845 32.3572 26.3096C32.9823 26.9348 33.3335 27.7826 33.3335 28.6667C33.3335 29.5507 32.9823 30.3986 32.3572 31.0237C31.7321 31.6488 30.8842 32 30.0002 32Z"
      fill="black"
    />
  </svg>
);

export default MinegocioMonitoringLogo;
