import { LoadingBuzz, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { iconById } from 'assets/apps_icons';
import { useAuthContext } from 'contexts';
import { useStore } from 'effector-react';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { execute as LoadNotificationService } from '../../services/notification/loadNotification/loadNotificationService';
import NotificationContentStore from '../../store/content/NotificationContentStore';
import { validateScrollPagination } from '../../utils/notificationContentUtils/notificationContentUtils';
import NotificationItemSkeleton from './NotificationItemSkeleton';
import { INotification, INotificationItem } from './NotificationItemTypes';
import NotificationItemView from './NotificationItemView';

export default function NotificationItem({
  isHighPriority = false,
  isUnread = false,
}: INotificationItem): JSX.Element {
  const { formatMessage } = useIntl();
  const { pagination, isLoading, notifications } = useStore(NotificationContentStore);
  const { appsWithScope } = useAuthContext();
  const [isBottom, setIsBottom] = useState(false);
  const { pageSize, pageNumber, pages } = pagination;
  const paginationPageNumber = useRef(pageNumber);


  useEffect(() => {
    LoadNotificationService({ pageNumber: 0, pageSize, priority: isHighPriority, unread: isUnread });
  }, []);

  useEffect(() => {
    if (isUnread && notifications.length > 0 && notifications.filter((notification) => !notification.read).length === 0) {
      LoadNotificationService({ pageNumber: 0, pageSize, priority: isHighPriority, unread: isUnread });
    }
  }, [notifications]);

  const appsById = appsWithScope.reduce((acc, app) => {
    acc[app.id] = app;
    return acc;
  }, {});

  useEffect(() => {
    if (validateScrollPagination(isBottom, paginationPageNumber, pagination)) {
      paginationPageNumber.current = pageNumber;
    }
  }, [isBottom, pagination]);

  async function handleInfinityScroll(event: React.UIEvent<HTMLDivElement, UIEvent>) {
    const target = event.target as HTMLDivElement;

    const isAtBottom = target.scrollHeight - target.scrollTop <= target.clientHeight + 1;

    setIsBottom(isAtBottom);

    if (isAtBottom && pageNumber < pages - 1) {
      if (!isLoading)
        await LoadNotificationService({
          pageNumber: Number(pageNumber) + 1,
          pageSize,
          priority: isHighPriority,
          unread: isUnread,
        });
      target.scrollTo({ top: target.scrollTop - 20, behavior: 'smooth' });
    }
    setIsBottom(false);
  }

  function getAppData(notificationName: string) {
    const regex = new RegExp(/^\[(.*?)\]/);
    const match = regex.exec(notificationName);

    const appId = match ? match[1] : null;

    if (!appId) return;

    return {
      ...appsById[appId],
      IconElement: iconById[appId],
    };
  }

  if (notifications.length === 0 && notifications?.length === 0 && !isLoading) {
    let message = 'Notification.noNotifications';
    if (isUnread) message = 'Notification.noUnreadNotifications';
    if (isHighPriority) message = 'Notification.noHighPriorityNotifications';
    return (
      <StyledEmptyNotifications>
        <Paragraph>
          {formatMessage({
            id: message
          })}
        </Paragraph>
      </StyledEmptyNotifications>
    );
  }

  return (
    <StyledListContainer onScroll={handleInfinityScroll} data-testid="notifications-list-container">
      {notifications.length === 0 && isLoading && <NotificationItemSkeleton quantity={10} />}
      <StyledList id="new-tab">
        {notifications?.filter((notification: INotification) => isUnread ? !notification.read : true).map((notification: INotification) => (
          <NotificationItemView
            key={notification.id}
            notification={notification}
            appData={getAppData(notification.notificationName)}
          />
        ))}

        {notifications.length > 0 &&
          <StyledDropdownFooter>
            {isLoading ? (
              <LoadingBuzz size="xlarge" />
            ) : (
              <Paragraph
                css={{
                  fontWeight: '$normal',
                  fontSize: '$2',
                  color: '#141414',
                  lineHeight: '$5',
                  maxWidth: '13.5rem',
                  textAlign: 'center',
                }}
              >
                {formatMessage({ id: 'Notification.noMoreNotifications' })}
              </Paragraph>
            )}
          </StyledDropdownFooter>
        }
      </StyledList>
    </StyledListContainer>
  );
}

const StyledListContainer = styled('div', {
  height: 'calc(100vh - 164px)',
  overflowY: 'auto',
  borderTop: '1px solid #DBDADA',
});

export const StyledList = styled('ul', {
  padding: '0',
  margin: '0',
  height: 'auto',
});

const StyledDropdownFooter = styled('footer', {
  padding: '$4',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderTop: '1px solid #DBDADA',
});

const StyledEmptyNotifications = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '4rem',
  '> p': {
    fontSize: '16px',
    color: '#141414',
    lineHeight: '1.5rem',
    fontWeight: 400,
  },
});
