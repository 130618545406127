/* eslint-disable react-hooks/rules-of-hooks */
import { useLogService } from 'admin-portal-shared-services';
import { loadNotifications, unreadNotification as unreadNotificationApi } from '../../../config/axiosConfig/notification/NotificationApi';
import { INotification } from '../../../notification-dropdown/notification-item/NotificationItemTypes';
import {
  setIsLoadingNotifications,
  setNotifications,
  setTotalUnreadNotifications,
} from '../../../store/content/NotificationContentEvents';

interface UnreadNotificationService {
  notificationId: string;
  notifications: INotification[];
}

export const execute = async ({
  notificationId,
  notifications,
}: UnreadNotificationService): Promise<void> => {
  const log = useLogService();
  setIsLoadingNotifications(true);
  return unreadNotificationApi(notificationId).then(() => {
    const updatedNotifications = notifications.map((notification) => {
      if (notificationId === notification.id) {
        notification.read = false;
      }
      return notification;
    });
    setNotifications({
      notifications: updatedNotifications,
    });
    loadNotifications().then((response: any) => {
      const notRead = response?.data?.notRead;
      setTotalUnreadNotifications(notRead || 0);
    });
    log.info('LoadNotificationService - load alerts');
  })
    .catch((error) => {
      log.error('LoadNotificationService: ', error);
      setIsLoadingNotifications(false);
    }).finally(() => { setIsLoadingNotifications(false); });
};

export default execute;
