import {
  SnackbarSubscription,
  ToastSubscription,
  TypeToast,
  useSnackbar,
  useToast,
} from 'admin-portal-shared-services';
import React, { useEffect } from 'react';
import NotificationView from './NotificationView';
import { Viewport } from './NotificationView.styles';
import Snackbar from './Snackbar/Snackbar';
import { SnackbarViewport } from './Snackbar/Snackbar.styles';

export type ToastType = {
  id: string;
  message: string;
  type: TypeToast | null;
  open?: boolean;
  showCloseButton?: boolean;
  actions?: [firstAction: Action, secondAction?: Action];
};

export type SnackbarType = {
  id: string;
  message: string;
  open?: boolean;
  actions?: [firstAction: Action];
};

export type Action = {
  name: string;
  action(): void;
};

export default function Notification(): JSX.Element {
  const [notifications, setNotifications] = React.useState<ToastType[]>([]);
  const [toastOpen, setToastOpen] = React.useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [snackbarList, setSnackbarList] = React.useState<SnackbarType[]>([]);
  const toastService = useToast();
  const snackbarService = useSnackbar();

  const deleteFirstNotification = (notifications): ToastType[] => {
    const newNotifications = [...notifications];
    newNotifications.shift();
    return newNotifications;
  };

  const deleteLastNotification = (snackbarList): SnackbarType[] => {
    const newSnackbarList = [...snackbarList];
    newSnackbarList.pop();
    return newSnackbarList;
  };

  useEffect(() => {
    const subscription = (notificationReceived: ToastSubscription) => {
      setNotifications((notifications) => [...notifications, notificationReceived]);
      setToastOpen(true);
    };

    toastService.subscribe(subscription);

    return () => {
      toastService.unsubscribe(subscription);
    };
  }, [toastService]);

  useEffect(() => {
    const snackbarSubscription = (newSnackbar: SnackbarSubscription) => {
      setSnackbarList((snackbarList) => [...snackbarList, newSnackbar]);
      setSnackbarOpen(true);
    };

    snackbarService.subscribe(snackbarSubscription);

    return () => {
      snackbarService.unsubscribe(snackbarSubscription);
    };
  }, [snackbarService]);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setNotifications((oldNotifications: ToastType[]) =>
          deleteFirstNotification(oldNotifications),
        );
        setSnackbarList((oldSnackbar: SnackbarType[]) => deleteLastNotification(oldSnackbar));
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <div>
      <Viewport>
        {notifications.map((notification) => (
          <NotificationView
            key={notification.id}
            id={notification.id}
            type={notification.type}
            message={notification.message}
            actions={notification.actions}
            setNotifications={setNotifications}
            open={toastOpen}
          />
        ))}
      </Viewport>
      <SnackbarViewport>
        {snackbarList.map((snackbar) => (
          <Snackbar
            key={snackbar.id}
            id={snackbar.id}
            message={snackbar.message}
            actions={snackbar.actions}
            setSnackbar={setSnackbarList}
            open={snackbarOpen}
          />
        ))}
      </SnackbarViewport>
    </div>
  );
}
