const PromotionsLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Brand/Hub/AppIcon/PromotionsManager</title>
    <defs>
      <path
        d="M12.472496,0 C12.7732152,0 13.0616177,0.119460251 13.2742584,0.332100862 L13.2742584,0.332100862 L23.0165255,10.0743751 C24.3354022,11.4011226 24.3354022,13.5438693 23.0141438,14.8730056 L23.0141438,14.8730056 L14.8847901,23.0023591 C14.2467598,23.6410996 13.3809787,24 12.4781653,24 C11.5753519,24 10.7095708,23.6410996 10.0724534,23.003272 L10.0724534,23.003272 L0.332567954,13.2747252 C0.119641625,13.0620467 0,12.773444 0,12.472496 L0,12.472496 L0,1.13386327 C0,0.507647877 0.507647877,0 1.13386327,0 L1.13386327,0 Z M12.0019427,2.26772654 L2.26772654,2.26772654 L2.26772654,12.0030766 L11.675957,21.3997265 C11.8531876,21.5771544 12.0831384,21.6898045 12.3290194,21.722426 L12.3290194,21.722426 L12.4781653,21.7322735 C12.7791031,21.7322735 13.0676968,21.61264 13.2808194,21.3992804 L13.2808194,21.3992804 L21.4082373,13.2718696 C21.8478629,12.8296204 21.8478629,12.1153715 21.410619,11.6755112 L21.410619,11.6755112 L12.0019427,2.26772654 Z M6.81451824,5.66931634 C7.44073364,5.66931634 7.94838151,6.17696422 7.94838151,6.80317961 C7.94838151,7.38466533 7.51066472,7.86391682 6.94675065,7.92941455 L6.80317961,7.93704288 C6.17696422,7.93704288 5.66931634,7.429395 5.66931634,6.80317961 C5.66931634,6.22169389 6.10703313,5.7424424 6.6709472,5.67694467 L6.81451824,5.66931634 Z"
        id="promotions-path-1"
      ></path>
    </defs>
    <g
      id="Brand/Hub/AppIcon/PromotionsManager"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group" transform="translate(8.000000, 8.000000)">
        <g id="tag" transform="translate(4.000000, 4.000000)">
          <mask id="promotions-mask-2" fill="white">
            <use xlinkHref="#promotions-path-1"></use>
          </mask>
          <g id="Mask" fillRule="nonzero"></g>
          <g id="Group" mask="url(#promotions-mask-2)" fillOpacity="0.9">
            <g
              transform="translate(-4.000000, -4.000000)"
              id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
            >
              <rect id="Rectangle" fill="#000000" x="0" y="0" width="32" height="32"></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PromotionsLogo;
