/* eslint-disable react-hooks/rules-of-hooks */
import { setupAxios } from './axiosConfig';

export interface RequestInterface {
  url: string;
  body?: unknown;
}

const axios = setupAxios();

export default axios;
