import { createEvent } from 'effector';
import { INotification } from '../../notification-dropdown/notification-item/NotificationItemTypes';
import { IPagination } from './NotificationItem';

export interface GetNotificationPayload {
  notifications: INotification[];
  notRead: number;
  pagination: IPagination;
}
export interface setNotificationsPayload {
  notifications: INotification[];
}
export const setIsLoadingNotifications = createEvent<boolean>('setIsLoadingNotifications');
export const loadNotificationsAlertsDone = createEvent<GetNotificationPayload>(
  'loadNotificationsAlertsDone',
);

export const updatePaginationDone = createEvent<IPagination>('updatePaginationDone');

export const setTotalUnreadNotifications = createEvent<number>('setTotalUnreadNotifications');
export const setNotifications = createEvent<setNotificationsPayload>(
  'setNotifications',
);
