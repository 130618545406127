/* eslint-disable react-hooks/rules-of-hooks */
import { getApiHost } from '../../services/host/HostService';
import { setupAxios } from './axiosConfig';

export interface RequestInterface {
  url: string;
  body?: unknown;
}

const axios = setupAxios();
const Api = {
  get: ({ url }: RequestInterface): Promise<unknown> =>
    axios.get(`${getApiHost()}${url}`).catch((error) => ({ hasError: true, ...error })),

  patch: ({ url, body }: RequestInterface): Promise<unknown> => {
    return axios.patch(`${getApiHost()}${url}`, body).catch((error) => ({
      hasError: true,
      ...error,
    }));
  },
};

export default Api;
