import { styled, useMatchMedia } from '@hexa-ui/theme';
import { useCallback, useEffect, useState } from 'react';
import Appbar from '../../components/Appbar/Appbar';
import { TopBarNav } from '../../components/TopBarNav/TopBarNav';
import { useGenerateAppMenuItems, useSidebar } from '../../hooks';
import { Sidebar } from '../Sidebar';

const Header = () => {
  const apps = useGenerateAppMenuItems();
  const { hasSidebar } = useSidebar();
  const [isMobile] = useMatchMedia(['tablet']);

  const [topbarHidden, setTopbarHidden] = useState(false);

  const handleBodyScroll = useCallback(() => {
    if (window.scrollY >= 56 && topbarHidden) return;
    if (window.scrollY >= 56 && isMobile) {
      setTopbarHidden(true);
      return;
    }
    setTopbarHidden(false);
  }, [isMobile, topbarHidden]);

  useEffect(() => {
    document.addEventListener('scroll', handleBodyScroll);
    () => document.removeEventListener('scroll', handleBodyScroll);
  }, [handleBodyScroll]);

  return (
    <StyledHeader data-testid="main-mfe-header">
      <TopBarNav apps={apps} />
      {isMobile ? <Appbar topbarHidden={topbarHidden} hasSidebar={hasSidebar} /> : null}
      {hasSidebar ? <Sidebar /> : null}
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled('header', {
  position: 'fixed',
  zIndex: '$6',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
});
