import { Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { MenuInteraction } from '../../../../../analytics';
import { AppCard } from '../../../../components';
import {
  AppMenuItem,
  AppMenuItems,
  useAnalyticsMenuNavigation,
} from '../../../../hooks';

const GridView = ({ apps }: AppMenuItems): JSX.Element => {
  const analyticsMenuNavigation = useAnalyticsMenuNavigation();

  return (
    <GridContainer type="fluid" style={{ margin: 0 }} data-testid="grid-view">
      {apps.map((app: AppMenuItem, index) => (
        <Grid.Item xs={6} sm={4} md={2} key={app.appId}>
          <AppCard
            iconBackgroundColor={app.appIconBackgroundColor}
            iconId={app.appId}
            appName={app.appName}
            appDescription={app.appDescription}
            homeRoute={app.homeRoute}
            baseRoute={app.baseRoute}
            isFirstEl={index === 0}
            onChangeRoute={() =>
              analyticsMenuNavigation.track(
                app.appNameAnalytics,
                MenuInteraction.Dashboard,
              )
            }
          />
        </Grid.Item>
      ))}
    </GridContainer>
  );
};
export default GridView;

const GridContainer = styled(Grid.Container, {
  rowGap: '$6',
  width: '100%',
});
