const BeesGrowPortalLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <clipPath id="bees-grow-portal-logo-1">
        <path d="M48 0v48H0V0h48Z" />
      </clipPath>
      <clipPath id="bees-grow-portal-logo-2">
        <path d="M24 0c13.255 0 24 10.745 24 24S37.255 48 24 48 0 37.255 0 24 10.745 0 24 0Z" />
      </clipPath>
      <clipPath id="bees-grow-portal-logo-3">
        <path d="M11.986 0a9 9 0 0 1 9 9v1.424a5 5 0 0 1-2 9.576h-5a1 1 0 0 1 0-2h3v-6a2 2 0 0 1 2-2V9a7 7 0 1 0-14 0v1a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2 5 5 0 0 1-2-9.576V9a9 9 0 0 1 9-9Zm-7 12a3 3 0 1 0 0 6Zm14 0v6a3 3 0 0 0 0-6Z" />
      </clipPath>
      <clipPath id="bees-grow-portal-logo-4">
        <path d="M32 0v32H0V0h32Z" />
      </clipPath>
    </defs>
    <g clipPath="url(#bees-grow-portal-logo-1)">
      <g clipPath="url(#bees-grow-portal-logo-3)" transform="translate(12.014 14)">
        <g clipPath="url(#bees-grow-portal-logo-4)" transform="translate(-4.014 -6)">
          <path fill="rgba(0, 0, 0, 0.9)" d="M4.014 6h23.972v20H4.014V6z" />
        </g>
      </g>
    </g>
  </svg>
);

export default BeesGrowPortalLogo;
