import { ChevronLeft, ChevronRight } from '@hexa-ui/icons';
import { useSidebarContext } from 'contexts';
import { FormattedMessage } from 'react-intl';
import Content from '../Content';
import { BackButton, BackButtonIcon, BackButtonText, Container } from './SidebarDesktop.styles';

export const SidebarDesktop = () => {
  const { isSidebarOpen, setIsSideBarOpen } = useSidebarContext();

  const handleOpenSidebar = (e) => {
    e.stopPropagation();
    setIsSideBarOpen(true);
  };
  const handleCloseSidebar = (e) => {
    e.stopPropagation();
    setIsSideBarOpen(false);
  };

  return (
    <Container
      onMouseEnter={handleOpenSidebar}
      onMouseLeave={handleCloseSidebar}
      className={isSidebarOpen ? 'open' : null}
      data-testid="side-nav-desktop-container"
    >
      <BackButton
        aria-label="desktop-app-back-button"
        type="button"
        tabIndex={0}
        className={isSidebarOpen ? 'open' : null}
      >
        <BackButtonIcon>
          {isSidebarOpen ? <ChevronLeft size="tiny" /> : <ChevronRight size="tiny" />}
        </BackButtonIcon>
        <BackButtonText className={isSidebarOpen ? 'open' : null}>
          <FormattedMessage id="Components.Sidebar.collapseMenu" />
        </BackButtonText>
      </BackButton>
      <Content />
    </Container>
  );
};

export default SidebarDesktop;
