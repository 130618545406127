import { HubTheme } from 'admin-portal-shared-services';
import { isPartnerApp } from 'utils/isPartnerApp';
import beesOne from '../../assets/BEES_One.svg';

const customizationPerUserTag: CustomizationPerUserTagType = {
  Partner: {
    logo: beesOne,
    theme: 'customer',
  },
};

type CustomizationPerUserTagType = {
  [key: string]: CustomizationType;
};

type CustomizationType = {
  logo: string;
  theme: HubTheme;
};

const findScope = (scope, decodedScopes) => decodedScopes.find((item) => item.includes(scope));

const hasCustomization = (decodedScopes): CustomizationType | null => {
  const userTag = findScope('UserTag.Profile', decodedScopes)?.split('.')[2];

  const hasCustomizationFromScope =
    userTag && Object.prototype.hasOwnProperty.call(customizationPerUserTag, userTag);

  const url = window.location.href;

  if (isPartnerApp(url)) {
    return customizationPerUserTag.Partner;
  }
  if (hasCustomizationFromScope) {
    return customizationPerUserTag[userTag];
  }
  return null;
};

export { CustomizationType, customizationPerUserTag, hasCustomization };
