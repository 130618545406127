import { useNavigateLogin } from 'hooks';
import { useEffect } from 'react';

export function RedirectToLoginPage() {
  const logoutCallback = useNavigateLogin();

  useEffect(() => {
    logoutCallback();
  }, [logoutCallback]);

  return null;
}

export default RedirectToLoginPage;
