import { Package } from '@hexa-ui/icons';
import { styled, useMatchMedia } from '@hexa-ui/theme';
import * as Dropdown from '@radix-ui/react-dropdown-menu';
import { DropdownButton as TDropdownButton } from 'admin-portal-shared-services';
import React from 'react';
import { toggleScrollbar } from '../../../../utils';
import { StyledButton, StyledIconButton } from '../HeaderButton/HeaderButton';

interface Props extends TDropdownButton {
  variant: 'primary' | 'secondary';
}

const DropdownButton = ({ label, onClick, options, variant, icon }: Props) => {
  const [isMobile] = useMatchMedia(['tablet']);
  const iconComponent = icon() as unknown as React.FC;

  return (
    <>
      <Dropdown.Root onOpenChange={toggleScrollbar}>
        <Dropdown.DropdownMenuTrigger asChild onClick={onClick}>
          {isMobile ? (
            <StyledIconButton size="small" icon={iconComponent ?? Package} />
          ) : (
            <StyledButton
              variant={variant}
              icon={iconComponent}
              leading={iconComponent ? true : false}
            >
              {label}
            </StyledButton>
          )}
        </Dropdown.DropdownMenuTrigger>

        <StyledDropdownContent>
          {options.map((item) => {
            const Icon = item.icon() as unknown as React.FC;
            return (
              <Dropdown.DropdownMenuItem key={item.label} asChild>
                <StyledMenuItem onClick={item.onClick}>
                  {<Icon />}
                  <div>{item.label}</div>
                </StyledMenuItem>
              </Dropdown.DropdownMenuItem>
            );
          })}
        </StyledDropdownContent>
      </Dropdown.Root>
    </>
  );
};

export default DropdownButton;

const StyledDropdownContent = styled(Dropdown.DropdownMenuContent, {
  background: '#fff',
  padding: '$2 0',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$1',
});

const StyledMenuItem = styled('button', {
  padding: '1rem 2rem',
  fontFamily: '$paragraph',
  display: 'flex',
  alignItems: 'center',
  outline: 'none',
  fontSize: '$3',
  lineHeight: '1.5rem',
  fontWeight: '$medium',
  background: '#fff',
  color: '$interfaceLabelPrimary',
  border: 'none',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$brandAccentNeutralBackground',
    outline: 'none',
  },
});
