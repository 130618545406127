const EngageLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Brand/Hub/AppIcon/Engage</title>
    <g id="Engage-Logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-7">
        <path
          d="M26.1687783,28.8696408 L34.6549015,28.8696408 L30.7444077,32.7844388 C29.371218,34.1591355 27.1448304,34.1591355 25.7716407,32.7844388 L24.0149626,31.0258203 L26.1687783,28.8696408 Z M13.3750237,28.8696408 L17.2855253,32.7844388 C18.6587134,34.1591355 20.885086,34.1591355 22.2582741,32.7844388 L24.0149626,31.0258203 L21.8611494,28.8696408 L13.3750237,28.8696408 L13.3750237,28.8696408 Z M28.6084164,21.5563896 L28.7965471,21.7446993 C30.0387078,22.9880288 30.0387078,25.0038828 28.7965471,26.2472124 L28.6120445,26.4318904 L37.0836116,26.4318904 C38.3929485,25.070652 38.3919703,22.9162523 37.0803301,21.5563896 L28.6084164,21.5563896 Z M19.2333781,26.2472124 C17.9912304,25.0038828 17.9912304,22.9880288 19.2333781,21.7446993 L19.4215088,21.5563896 L10.9495992,21.5563896 C9.63795972,22.9162523 9.6369691,25.070652 10.9463178,26.4318904 L19.4178806,26.4318904 L19.2333781,26.2472124 Z M34.6549015,19.1186392 L30.7467137,15.2138774 C29.3730535,13.8414257 27.1459029,13.8414257 25.7722427,15.2138774 L24.0149626,16.9696114 L26.1658703,19.1186392 L34.6549015,19.1186392 Z M24.0149626,16.9696114 L22.2576722,15.2138774 C20.8840135,13.8414257 18.656878,13.8414257 17.2832193,15.2138774 L13.3750237,19.1186392 L21.8640449,19.1186392 L24.0149626,16.9696114 Z"
          id="Shape"
          fill="#000000"
          fillRule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
);

export default EngageLogo;
