const MinegocioCompetitionAdminToolAppLogo = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.857178" width="24" height="24" rx="12" fill="#FFFF00" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.35718 17H18.3572C18.6333 17 18.8572 17.2239 18.8572 17.5C18.8572 17.7761 18.6333 18 18.3572 18H9.35718C7.97715 17.9983 6.85883 16.88 6.85718 15.5V6.5C6.85718 6.22386 7.08104 6 7.35718 6C7.63332 6 7.85718 6.22386 7.85718 6.5V15.5C7.85718 16.3284 8.52875 17 9.35718 17ZM10.3572 15.5C10.3572 15.7761 10.1333 16 9.85718 16C9.58104 16 9.35718 15.7761 9.35718 15.5V12C9.35718 11.7239 9.58104 11.5 9.85718 11.5C10.1333 11.5 10.3572 11.7239 10.3572 12V15.5ZM11.8572 11V15.5C11.8572 15.7761 12.081 16 12.3572 16C12.6333 16 12.8572 15.7761 12.8572 15.5V11C12.8572 10.7239 12.6333 10.5 12.3572 10.5C12.081 10.5 11.8572 10.7239 11.8572 11ZM14.3572 15.5V12.5C14.3572 12.2239 14.581 12 14.8572 12C15.1333 12 15.3572 12.2239 15.3572 12.5V15.5C15.3572 15.7761 15.1333 16 14.8572 16C14.581 16 14.3572 15.7761 14.3572 15.5ZM16.8572 10.5V15.5C16.8572 15.7761 17.081 16 17.3572 16C17.6333 16 17.8572 15.7761 17.8572 15.5V10.5C17.8572 10.2239 17.6333 10 17.3572 10C17.081 10 16.8572 10.2239 16.8572 10.5ZM10.2107 10.3535C10.1169 10.4473 9.98978 10.5 9.85718 10.5C9.65498 10.5 9.47271 10.3781 9.39534 10.1913C9.31797 10.0045 9.36072 9.7895 9.50368 9.6465L11.2967 7.8535C11.8824 7.26793 12.8319 7.26793 13.4177 7.8535L14.5037 8.939C14.6989 9.13419 15.0154 9.13419 15.2107 8.939L18.0037 6.1465C18.1999 5.95701 18.5117 5.95972 18.7046 6.15259C18.8975 6.34546 18.9002 6.65731 18.7107 6.8535L15.9177 9.6465C15.3319 10.2321 14.3824 10.2321 13.7967 9.6465L12.7107 8.5605C12.5127 8.37193 12.2016 8.37193 12.0037 8.5605L10.2107 10.3535Z"
      fill="#141414"
    />
  </svg>
);

export default MinegocioCompetitionAdminToolAppLogo;
