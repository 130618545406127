/* eslint-disable react-hooks/rules-of-hooks */
import { useLogService } from 'admin-portal-shared-services';
import { loadNotifications, readNotification as readNotificationApi } from '../../../config/axiosConfig/notification/NotificationApi';
import { INotification } from '../../../notification-dropdown/notification-item/NotificationItemTypes';
import {
  setIsLoadingNotifications,
  setNotifications,
  setTotalUnreadNotifications,
} from '../../../store/content/NotificationContentEvents';

export interface ReadNotificationService {
  notificationIds: Array<string>;
  notifications: INotification[];
  unreadOnly?: boolean;
}

export const execute = async ({
  notificationIds,
  notifications,
}: ReadNotificationService): Promise<void> => {
  const log = useLogService();
  setIsLoadingNotifications(true);
  return readNotificationApi(notificationIds)
    .then(() => {
      let updatedNotifications = [];
      updatedNotifications = notifications.map((notification) => {
        if (notificationIds.includes(notification.id)) {
          notification.read = true;
        }
        return notification;
      });
      setNotifications({
        notifications: updatedNotifications,
      });
      loadNotifications().then((response: any) => {
        const notRead = response?.data?.notRead;
        setTotalUnreadNotifications(notRead || 0);
      });
      log.info('LoadNotificationService - load alerts');
    })
    .catch((error) => {
      log.error('LoadNotificationService: ', error);
      setIsLoadingNotifications(false);
    }).finally(() => { setIsLoadingNotifications(false); });
};

export default execute;
