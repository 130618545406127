import { PrefferedLanguageInstance, usePreferredLanguageV2 } from 'admin-portal-shared-services';
import * as React from 'react';

export const LanguageContext = React.createContext<PrefferedLanguageInstance>(
  {} as PrefferedLanguageInstance,
);
export const PreferredLanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const preferredLanguage = usePreferredLanguageV2();

  return <LanguageContext.Provider value={preferredLanguage}>{children}</LanguageContext.Provider>;
};

export const useLanguageContext = () => React.useContext(LanguageContext);

export default PreferredLanguageProvider;
