import { useSidebarState } from 'admin-portal-shared-services';
import { useMemo } from 'react';

export const useSidebar = () => {
  const { items, utils } = useSidebarState();

  const hasSidebar = useMemo(() => {
    return items.length > 0 || utils.length > 0;
  }, [items, utils]);

  return { hasSidebar, items, utils };
};
