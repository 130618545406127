import { datadogRum } from '@datadog/browser-rum';
import { useEnvService } from 'admin-portal-shared-services';
import { useEffect } from 'react';

const getCurrentEnv = (envRaw: string) => {
  const env = envRaw.toLowerCase();

  if (env === 'qa') {
    return 'sit';
  } else if (env) {
    return env;
  } else {
    return '';
  }
};

export const useDatadog = (appId?: string, clientId?: string) => {
  const envService = useEnvService();
  const envRaw = envService.getEnv();
  const env = getCurrentEnv(envRaw);

  useEffect(() => {
    if (appId && clientId) {
      datadogRum.init({
        applicationId: appId,
        clientToken: clientId,
        site: 'datadoghq.com',
        service: 'bees-hub-main-mfe',
        env: env,
        version: '1.0.0',
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
      });

      datadogRum.startSessionReplayRecording();
    }
  }, [appId, clientId, env]);
};
