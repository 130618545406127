import { Paragraph, Radio, TextLink, Tooltip } from '@hexa-ui/components';
import { AlertTriangle } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import { iconById } from 'assets/apps_icons';
import NotificationContentStore from 'components/Notification/store/content/NotificationContentStore';
import { timeSince } from 'components/Notification/utils/notificationContentUtils/notificationContentUtils';
import { useStore } from 'effector-react';
import { useIntl } from 'react-intl';
import { execute as readNotification } from '../../services/notification/read/readNotificationService';
import { execute as unreadNotification } from '../../services/notification/unread/unreadNotificationService';
import { INotificationItemView } from './NotificationItemTypes';

export default function NotificationItemView({
  notification,
  appData,
}: INotificationItemView): JSX.Element {
  const { formatMessage, locale } = useIntl();
  const { notifications } = useStore(NotificationContentStore);
  const { title, priority, read, formattedMessage, inAppUrl } = notification;

  const translatedAppName = appData?.appName?.[locale];
  const IconElement = appData?.IconElement ?? iconById.GENERIC;

  const tooltipMessage = !read
    ? formatMessage({ id: 'Notification.markRead' })
    : formatMessage({ id: 'Notification.markUnread' });

  const handleReadNotificationClick = async () => {
    if (notification.read) {
      await unreadNotification({
        notificationId: notification.id,
        notifications,
      });
    } else {
      await readNotification({
        notificationIds: [notification.id],
        notifications,
      });
    }
  };

  return (
    <StyledItemContainer data-testid="notification-item-view">
      <StyledNotificationHeader>
        <StyledFullWidth>
          <StyledAppIcon css={{ backgroundColor: appData?.appIconBackgroundColor }}>
            <IconElement />
          </StyledAppIcon>
          <Paragraph
            css={{
              fontSize: '$2',
              lineHeight: '$5',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              marginRight: '$4',
              maxWidth: '190px',
              display: '-webkit-box',
              '-webkit-line-clamp': 2,
              '-webkit-box-orient': 'vertical',
            }}
          >
            {translatedAppName}
          </Paragraph>
        </StyledFullWidth>
        <StyledRadio>
          <Paragraph size="small" color="#757575" css={{ whiteSpace: 'nowrap' }}>
            {timeSince(notification.createdAt, locale, formatMessage)}
          </Paragraph>
          <StyledRadioRoot>
            <Tooltip text={tooltipMessage} placement="left">
              <Radio.Item
                label=""
                onClick={handleReadNotificationClick}
                checked={!read}
                value="radio"
                id="radio"
                data-testid="notification-radio"
              />
            </Tooltip>
          </StyledRadioRoot>
        </StyledRadio>
      </StyledNotificationHeader>
      <StyledRow>
        <Paragraph
          size="basis"
          css={{ fontWeight: '$semibold', color: '#141414', fontSize: '$2', lineHeight: '$5' }}
        >
          {title}
        </Paragraph>
        {priority ? (
          <Tooltip text={formatMessage({ id: 'Notification.highPriority' })} placement="top">
            <StyledHighPriorityIcon />
          </Tooltip>
        ) : null}
      </StyledRow>
      <StyledRow>
        <Paragraph
          css={{ fontWeight: '$normal', color: '#757575', fontSize: '$2', lineHeight: '$5' }}
        >
          {formattedMessage}
        </Paragraph>
      </StyledRow>
      {!!inAppUrl && (
        <TextLink
          href={inAppUrl}
          css={{ marginTop: '$2', marginLeft: '$12', display: 'block' }}
          hasUnderline={false}
        >
          {formatMessage({ id: 'Notification.showMore' })}
        </TextLink>
      )}
    </StyledItemContainer>
  );
}

const StyledItemContainer = styled('li', {
  padding: '1rem',
  listStyle: 'none',
  '& + &': {
    borderTop: '1px solid #DBDADA',
  },
});

const StyledNotificationHeader = styled('header', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '> div': {
    display: 'flex',
    alignItems: 'center',
    gap: '$2',
  },
});

const StyledAppIcon = styled('div', {
  width: '2.5rem',
  height: '2.5rem',
  borderRadius: '50%',
  backgroundColor: '$brandPrimaryBasis',
  '& > svg': {
    width: '40px',
    height: '40px',
  },
});

const StyledRadio = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
});

const StyledRadioRoot = styled(Radio.Root, {
  '> div': {
    '> div': {
      '> button': {
        width: '8px',
        height: '8px',
        border: '0.0625rem solid $semanticInfoBasis !important',
        '> span': {
          '&::after': {
            backgroundColor: '$semanticInfoBasis',
          },
        },
      },
      '> label': {
        display: 'none',
      },
    },
  },
});

const StyledRow = styled('div', {
  marginLeft: '$12',
  marginTop: '$2',
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  '& > div': {
    width: '24px',
    height: '24px',
  },
});

const StyledHighPriorityIcon = styled(AlertTriangle, {
  fill: '#FFAA33 !important',
});

const StyledFullWidth = styled('div', {
  width: '100%',
});
