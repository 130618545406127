import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGenerateAppMenuItems } from '../../hooks';
import DashboardView from './components/DashboardView';
import EmptyView from './components/EmptyView';

export function Dashboard() {
  const apps = useGenerateAppMenuItems();
  const navigate = useNavigate();

  const appsWithScope = useMemo(() => apps.filter((app) => !app.hidden), [apps]);

  const hasAccessToAppCatalog = apps.some((app) => app.appId === 'BEES_HUB_APP_CATALOG');

  useEffect(() => {
    if (appsWithScope.length === 1) {
      navigate(appsWithScope[0].homeRoute);
    }
  }, [appsWithScope, navigate]);

  if (appsWithScope.length === 1) return null;
  if (!appsWithScope.length) return <EmptyView />;

  return (
    <DashboardView appsWithScope={appsWithScope} hasAccessToAppCatalog={hasAccessToAppCatalog} />
  );
}

export default Dashboard;
