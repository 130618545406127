import ArrowBack from '@material-ui/icons/ArrowBack';
import * as Dialog from '@radix-ui/react-dialog';
import { useSidebarContext } from 'contexts';
import { useSwipeable } from 'react-swipeable';
import Content from '../Content';
import { BackButton, DialogContent, Overlay } from './SidebarMobile.styles';

export const SidebarMobile = () => {
  const { isSidebarOpen, toggleSidebar } = useSidebarContext();

  const handlers = useSwipeable({
    onSwipedLeft: () => toggleSidebar(),
    preventScrollOnSwipe: true,
    trackTouch: true,
    trackMouse: false,
  });

  return (
    <Dialog.Root open={isSidebarOpen}>
      <Dialog.Portal>
        <Overlay data-testid="sidenav-overlay">
          <DialogContent
            onEscapeKeyDown={(event) => {
              event.preventDefault();
              toggleSidebar();
            }}
            onPointerDownOutside={(event) => {
              event.preventDefault();
              toggleSidebar();
            }}
            {...handlers}
            data-testid="sidenav-content"
          >
            <BackButton>
              <Dialog.Close asChild onClick={toggleSidebar} data-testid="sidenav-close-button">
                <ArrowBack />
              </Dialog.Close>
            </BackButton>
            <Content />
          </DialogContent>
        </Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default SidebarMobile;
