import { DocumentTitle } from 'admin-portal-shared-services';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppMenuItem, useGenerateAppMenuItems } from '../../hooks';

const DEFAULT_BEES_ONE_TITLE = 'BEES One';

export const AppTitleContext = createContext<string>('');

export const useAppTitleContext = () => useContext(AppTitleContext);

export const AppTitleProvider = ({ children }: { children: React.ReactNode }) => {
  const [title, setTitle] = useState('');
  const apps = useGenerateAppMenuItems();
  const { pathname } = useLocation();

  useEffect(() => {
    const { appName } =
      apps.find(
        (app: AppMenuItem) => `/${app.homeRoute.split('/')[1]}` === `/${pathname.split('/')[1]}`,
      ) || {};

    setTitle(appName || DEFAULT_BEES_ONE_TITLE);
  }, [apps, pathname]);

  return (
    <>
      <DocumentTitle>{title}</DocumentTitle>
      <AppTitleContext.Provider value={title}>{children}</AppTitleContext.Provider>
    </>
  );
};
