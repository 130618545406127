import { styled, themes } from '@hexa-ui/theme';
import { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { iconById } from '../../assets/apps_icons';
import { useAuthContext, useEnvContext } from '../../contexts';

export interface AppCardProps {
  appName: string;
  appDescription: string;
  iconId?: string;
  disabled?: boolean;
  homeRoute: string;
  baseRoute: string;
  isFirstEl: boolean;
  iconBackgroundColor: string;
  onChangeRoute: () => void;
}

export const AppCard = ({
  appName,
  appDescription,
  homeRoute,
  baseRoute,
  iconId,
  disabled = false,
  isFirstEl,
  iconBackgroundColor,
  onChangeRoute,
}: AppCardProps): JSX.Element => {
  const envContextValues = useEnvContext();
  const { customization } = useAuthContext();
  const ref = useRef<HTMLAnchorElement | null>(null);
  const { pathname } = useLocation();
  const IconEl = iconById[iconId] || iconById.GENERIC;

  useEffect(() => {
    if (pathname.includes(baseRoute)) {
      ref?.current?.focus();
      return;
    }
    if (isFirstEl && ref?.current) ref?.current?.focus();
  }, [baseRoute, isFirstEl, pathname]);

  return (
    <Card
      disabled={disabled}
      active={pathname.includes(baseRoute)}
      ref={ref}
      to={homeRoute}
      data-parent="app-switcher"
      data-testid="app-card"
      onClick={onChangeRoute}
    >
      <AppIcon
        className={themes?.[customization?.theme || envContextValues.defaultTheme] || 'customer'}
        css={{ backgroundColor: iconBackgroundColor || '$brandPrimaryBasis' }}
      >
        <IconEl />
      </AppIcon>
      <Title disabled={disabled}>{appName}</Title>
      <Description disabled={disabled}>{appDescription}</Description>
    </Card>
  );
};

export default AppCard;

const Card = styled(Link, {
  all: 'unset',
  boxShadow: '$025',
  width: '100%',
  height: '9rem',
  textDecoration: 'inherit',
  cursor: 'pointer',
  borderRadius: '$4',
  padding: '$4',
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  transition: 'box-shadow 200ms ease-in',
  variants: {
    disabled: {
      true: {
        color: '$interfaceLabelDisabled',
        cursor: 'auto',
      },
      false: {
        '&:hover': {
          boxShadow: '$4',
        },
        '&:focus': {
          outline: '2px solid black',
        },
        '&:active': {
          boxShadow: '$2',
        },
      },
    },
    active: {
      true: {
        border: '1px solid #000',
      },
    },
  },
});

const Title = styled('div', {
  color: '$interfaceLabelPrimary',
  fontFamily: '$heading',
  fontSize: '1rem',
  fontWeight: '$semibold',
  lineHeight: '$6',
  marginTop: '$2',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  overflowWrap: 'anywhere',
  variants: {
    disabled: {
      true: {
        color: '$interfaceLabelDisabled',
      },
    },
  },
  '@tablet': {
    fontSize: '$5',
  },
});

const Description = styled('div', {
  color: '$interfaceLabelPrimary',
  fontFamily: '$paragraph',
  fontSize: '$1',
  marginTop: '$1',
  fontWeight: '$normal',
  lineHeight: '$4',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  variants: {
    disabled: {
      true: {
        color: '$interfaceLabelDisabled',
      },
    },
  },
});

const AppIcon = styled('div', {
  height: '2rem',
  width: '2rem',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '50%',
  '@tablet': {
    height: '3rem',
    width: '3rem',
  },
});
