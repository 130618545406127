import appHeader from 'i18n/messages/appHeader';
import MessageMap from '../i18n';

const koKR: MessageMap = {
  Components: {
    Sidebar: {
      collapseMenu: 'Collapse menu',
    },
  },
  test: {
    translate: 'Translate',
    missing: 'Just English option available',
  },
  Error500: {
    title: 'Ooops! Something went wrong',
  },
  Error403: {
    title: 'Hmm... Access denied!',
    description: '이 페이지에 엑세스 할 수 있는 권한이 없습니다.',
    button: 'Home',
  },
  Error404: {
    title: '이 페이지가 삭제되었습니다.',
    description: '이 페이지를 찾을 수 없거나 존재하지 않는 것 같습니다.',
    button: 'Home',
  },
  Error503: {
    title: '서비스를 사용할수 없습니다.',
    description:
      '서비스가 일시적으로 너무 바쁩니다. 몇 분후에 다시 시도해주십시오.',
    button: '다시 시도하십시오.',
    infoText: '문제가 지속되면 문제를 보고할 수 있습니다.',
  },
  AvatarMenu: {
    signOut: 'Sign Out',
    userProfile: '사용자 프로필',
  },
  Dashboard: {
    pageTitle: 'Home',
    errorPage: '표시할 내용이 없습니다.',
  },
  Notification: {
    title: '알림',
    allTab: '전체',
    unreadTab: '읽지 않음',
    highPriorityTab: '높은 우선 순위',
    markAllRead: '모두 읽음으로 표시',
    markUnread: '안 읽음으로 표시',
    markRead: '읽음으로 표시',
    highPriority: '높은 우선 순위',
    noNotifications: '아직 알림이 없습니다.',
    noUnreadNotifications: '아직 읽지 않은 알림이 없습니다.',
    noHighPriorityNotifications: '아직 우선순위가 높은 알림이 없습니다.',
    noMoreNotifications: '더 표시할 알림이 없습니다.',
    showMore: '더 보기',
    yesterday: 'Yesterday, {value}',
    passwordChanged: '비밀번호가 성공적으로 변경되었습니다',
  },
  AppSwitcher: {
    yourApps: '내 앱',
    tools: '도구',
    reload: '새로고침',
    error: '오류가 발생하여 메뉴를 불러올 수 없습니다.',
  },
  UpdatesPopover: {
    updates: '업데이트',
    title: '새로운 BEES One 메뉴',
    description:
      '이제 이 메뉴의 도구를 쉽게 탐색할 수 있습니다. 한번 시도해 보십시오!',
    pagination: '1 중 1',
    gotIt: '알겠습니다',
  },
  AppSwitcherButton: {
    chooseAnApp: '앱 선택',
  },
  ApprovalFlow: {
    showApprovalFlowButton: 'Approval flow',
  },
  AppCatalog: {
    showAppCatalogButton: '앱 카탈로그 보기',
  },
  AdminTool: {
    showAdminToolButton: 'BEES One 관리 도구',
  },
  TermsAndConditions: {
    showTermsAndConditionsButton: 'Terms & Conditions',
  },
  PrivacyPolicy: {
    showPrivacyPolicyButton: 'Privacy Policy',
  },
  TermsAndPolicies: {
    titleFirstLogin: '이용 약관 및 개인정보 보호정책',
    titleUpdate: '이용 약관 및 개인정보 보호정책 업데이트',
    descriptionParagraph1:
      '계속 진행하려면, 아래 두 확인란 모두에 체크 표시하여 BEES ONE의 이용 약관 및 개인정보 보호정책을 읽고 이해했음을 확인해 주십시오.',
    descriptionParagraph2:
      '두 문서 모두 클릭이 가능하며 여러 언어로 표시됩니다. 설정 메뉴로 이동하여 언제든지 약관을 다시 확인할 수 있습니다.',
    agreeTerms: '본인은 BEES One의 {link} 읽고 이해했습니다.',
    agreePolicies: '본인은 BEES One의 {link} 읽고 이해했습니다.',
    terms: '이용 약관',
    policies: '개인정보 보호정책',
    termsLink: '이용 약관을',
    policiesLink: '개인정보 보호정책을',
    termsError: '계속 진행하려면 약관에 동의해야 합니다.',
    policiesError: '계속 진행하려면 정책에 동의해야 합니다.',
    declineButton: '거부',
    agreeButton: '계속 진행',
  },
  ...appHeader.enUS,
};

export default koKR;
