const BeesForceLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Brand/Hub/AppIcon/BEESForceAdmin</title>
    <g
      id="Brand/Hub/AppIcon/BEESForceAdmin"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <circle id="Oval-Copy-2" fill="#000000" cx="24" cy="24" r="24"></circle>
      <g id="Group" transform="translate(12.000000, 13.000000)" fill="#FFFF00" fillRule="nonzero">
        <g id="BEES_Force_Icon_RGB_Yellow" transform="translate(3.368421, 2.842105)">
          <path
            d="M17.2631579,6.89920192 C17.2631579,7.88141392 17.2631579,8.64249299 17.2631579,8.97429209 C17.2631579,9.75302087 16.9331966,10.6187673 16.4025001,11.5077375 L0.860657807,11.5077375 C0.329961303,10.6187673 1.77635684e-15,9.75302087 1.77635684e-15,8.97429209 C1.77635684e-15,8.46257163 1.77635684e-15,7.72238442 1.77635684e-15,6.89920192 L17.2631579,6.89920192 Z M17.2631579,4.5979509 L17.2631579,2.32732683 C17.2631579,2.12342751 17.1579184,1.93397044 16.9848194,1.82622568 L14.4327361,0.2377684 C14.1830567,0.0823624607 13.8948445,-2.56418107e-15 13.6007547,-2.56418107e-15 C12.5947121,-2.56418107e-15 11.1335565,-2.56418107e-15 3.6624205,-2.56418107e-15 C3.36833077,-2.56418107e-15 3.0801185,0.0823711295 2.83043915,0.2377684 L0.278355817,1.82622568 C0.105248146,1.93397044 1.73376404e-05,2.12342751 1.73376404e-05,2.32732683 L1.73376404e-05,4.5979509 L17.2631579,4.5979509 Z M2.59079828,13.8089885 C3.92685419,15.3182647 5.52677164,16.7352441 6.74444614,17.740073 C7.83944816,18.6436675 9.42374441,18.6436675 10.5187464,17.740073 C11.7364123,16.7352528 13.3363297,15.3182647 14.6723856,13.8089885 L2.59079828,13.8089885 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default BeesForceLogo;
