const PartnerReportsLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Brand/Hub/AppIcon/PartnerReports</title>
    <g
      id="Brand/Hub/AppIcon/PartnerReports"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="home-location-alt"
        transform="translate(8.000000, 8.000000) scale(0.5, 0.5)"
        fill="#000000"
        fillRule="nonzero"
        fillOpacity="0.9"
      >
        <path
          d="M47.6666667,24 C49.0342289,24 50.1613524,25.0294405 50.3153927,26.355677 L50.3333333,26.6666667 L50.3333333,53.3333333 C50.3333333,54.8060927 49.139426,56 47.6666667,56 C46.2991044,56 45.1719809,54.9705595 45.0179406,53.644323 L45,53.3333333 L45,26.6666667 C45,25.1939073 46.1939073,24 47.6666667,24 Z M31.6666667,8 C33.0342289,8 34.1613524,9.02944051 34.3153927,10.355677 L34.3333333,10.6666667 L34.3333333,53.3333333 C34.3333333,54.8060927 33.139426,56 31.6666667,56 C30.2991044,56 29.1719809,54.9705595 29.0179406,53.644323 L29,53.3333333 L29,10.6666667 C29,9.19390733 30.1939073,8 31.6666667,8 Z M15.6666667,34.6666667 C17.0342289,34.6666667 18.1613524,35.6961072 18.3153927,37.0223437 L18.3333333,37.3333333 L18.3333333,53.3333333 C18.3333333,54.8060927 17.139426,56 15.6666667,56 C14.2991044,56 13.1719809,54.9705595 13.0179406,53.644323 L13,53.3333333 L13,37.3333333 C13,35.860574 14.1939073,34.6666667 15.6666667,34.6666667 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);
export default PartnerReportsLogo;
