import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

interface SidebarContextProps {
  isSidebarOpen: boolean;
  toggleSidebar?: () => void;
  setIsSideBarOpen: (value: boolean) => void;
}

export const SidebarContext = createContext<SidebarContextProps>({
  isSidebarOpen: false,
  toggleSidebar: null,
  setIsSideBarOpen: () => null,
});

export const useSidebarContext = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const [isSidebarOpen, setIsSideBarOpen] = useState(false);
  const toggleSidebar = useCallback(() => setIsSideBarOpen((prev) => !prev), [setIsSideBarOpen]);

  return (
    <SidebarContext.Provider value={{ isSidebarOpen, toggleSidebar, setIsSideBarOpen }}>
      {children}
    </SidebarContext.Provider>
  );
};
