import { Badge, IconButton, Tooltip } from '@hexa-ui/components';
import { Bell } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import { useStore } from 'effector-react';
import { useIntl } from 'react-intl';
import NotificationContentStore from '../store/content/NotificationContentStore';

interface NotificationsInterface {
  openNotificationDropdown: () => void;
}

const MAX_COUNTER = 99;

export const NotificationsBell = ({
  openNotificationDropdown,
}: NotificationsInterface): JSX.Element => {
  const { notRead } = useStore(NotificationContentStore);
  const { formatMessage } = useIntl();

  const dispatchSegment = () => {
    openNotificationDropdown();
  };

  return (
    <StyledMargin>
      <StyledBellContainer>
        <Tooltip text={formatMessage({ id: 'Notification.title' })} placement="bottom">
          <IconButton
            icon={Bell}
            variant="inherit"
            onClick={dispatchSegment}
            id="notificationsButton"
            data-testid="notificationsButton"
          />
        </Tooltip>
        {notRead ? (
          <StyledBadgeCounter
            css={{ background: '#047AF1' }}
            counterLabel={Math.min(notRead, MAX_COUNTER)}
            id="notificationsCounter"
          />
        ) : null}
      </StyledBellContainer>
    </StyledMargin>
  );
};

export default NotificationsBell;

export const StyledBellContainer = styled('div', {
  position: 'relative',
});

export const StyledBadgeCounter = styled(Badge.Counter, {
  position: 'absolute',
  top: '2px',
  right: '5px',
  '> p': {
    color: '#FFFFFF',
  },
});

export const StyledMargin = styled('div', {
  marginRight: '1rem',
});
