const DataBrewLogo = () => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Brand/Hub/AppIcon/DataBrew</title>
    <defs>
      <path
        d="M1,0 C1.55228475,0 2,0.44771525 2,1 L2,1 L2,21 C2,21.5522847 2.44771525,22 3,22 L3,22 L23,22 C23.5522847,22 24,22.4477153 24,23 C24,23.5522847 23.5522847,24 23,24 L23,24 L3,24 C1.34314575,24 0,22.6568542 0,21 L0,21 L0,1 C0,0.44771525 0.44771525,0 1,0 Z M15,11 C15.5522847,11 16,11.4477153 16,12 L16,12 L16,19 C16,19.5522847 15.5522847,20 15,20 C14.4477153,20 14,19.5522847 14,19 L14,19 L14,12 C14,11.4477153 14.4477153,11 15,11 Z M7,11 C7.55228475,11 8,11.4477153 8,12 L8,12 L8,19 C8,19.5522847 7.55228475,20 7,20 C6.44771525,20 6,19.5522847 6,19 L6,19 L6,12 C6,11.4477153 6.44771525,11 7,11 Z M19,6 C19.5522847,6 20,6.44771525 20,7 L20,7 L20,19 C20,19.5522847 19.5522847,20 19,20 C18.4477153,20 18,19.5522847 18,19 L18,19 L18,7 C18,6.44771525 18.4477153,6 19,6 Z M11,6 C11.5522847,6 12,6.44771525 12,7 L12,7 L12,19 C12,19.5522847 11.5522847,20 11,20 C10.4477153,20 10,19.5522847 10,19 L10,19 L10,7 C10,6.44771525 10.4477153,6 11,6 Z"
        id="data-brew-path-1"
      ></path>
    </defs>
    <g id="Brand/Hub/AppIcon/DataBrew" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(8.000000, 8.000000)">
        <g
          id="01_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
          transform="translate(4.000000, 4.000000)"
        >
          <mask id="data-brew-mask-2" fill="white">
            <use xlinkHref="#data-brew-path-1"></use>
          </mask>
          <g id="Mask" fillRule="nonzero"></g>
          <g id="Group" mask="url(#data-brew-mask-2)" fillOpacity="0.9">
            <g
              transform="translate(-4.000000, -4.000000)"
              id="01_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
            >
              <rect id="Rectangle" fill="#000000" x="0" y="0" width="32" height="32"></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DataBrewLogo;
