import { IconButton } from '@hexa-ui/components';
import { Globe, Menu } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import { useAppHeader } from 'admin-portal-shared-services';
import CountrySelect from 'components/InnerHeader/components/CountrySelect/CountrySelect';
import OptionalButtons from 'components/InnerHeader/components/OptionalButtons/OptionalButtons';
import VendorSelect from 'components/InnerHeader/components/VendorSelect/VendorSelect';
import { useAppTitleContext, useSidebarContext } from 'contexts';
import { useState } from 'react';
import { PageTitle, SidebarButton, StyledContainer, StyledHeaderRow } from './Appbar.styles';

interface Props {
  hasSidebar: boolean;
  topbarHidden: boolean;
}

const Appbar = ({ hasSidebar, topbarHidden }: Props): JSX.Element => {
  const { toggleSidebar } = useSidebarContext();
  const defaultTitle = useAppTitleContext();
  const [showSelects, setShowSelects] = useState(false);
  const [headerConfig] = useAppHeader();

  const { countrySelect } = headerConfig;

  const toggleShowSelects = () => setShowSelects((prev) => !prev);

  return (
    <StyledContainer topbarHidden={topbarHidden} data-testid="main-mfe-app-bar">
      <StyledRowsContainer>
        <StyledHeaderRow>
          <StyledFirstRowContent>
            <StyledFlex>
              {hasSidebar ? (
                <SidebarButton onClick={toggleSidebar}>
                  <Menu size="medium" />
                </SidebarButton>
              ) : null}
              <PageTitle>{headerConfig.pageTitle || defaultTitle}</PageTitle>
            </StyledFlex>
            <StyledButtonsContainer>
              {countrySelect && (
                <IconButton icon={Globe} onClick={toggleShowSelects} size="small" />
              )}
              <OptionalButtons />
            </StyledButtonsContainer>
          </StyledFirstRowContent>
        </StyledHeaderRow>
        {showSelects && (
          <StyledHeaderRow>
            <StyledSelectsContainer>
              <CountrySelect />
              <VendorSelect />
            </StyledSelectsContainer>
          </StyledHeaderRow>
        )}
      </StyledRowsContainer>
    </StyledContainer>
  );
};

const StyledFlex = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

const StyledButtonsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  columnGap: '$2',
});

const StyledRowsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const StyledSelectsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  columnGap: '$2',
  height: '100%',
});

const StyledFirstRowContent = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
});

export default Appbar;
