import appHeader from 'i18n/messages/appHeader';
import MessageMap from '../i18n.d';

const es419: MessageMap = {
  Components: {
    Sidebar: {
      collapseMenu: 'Colapsar menu',
    },
  },
  test: {
    translate: 'Traducción',
  },
  Error500: {
    title: '¡Huy! Algo salió mal',
  },
  Error403: {
    title: 'Hmm... ¡Acceso denegado!',
    description: 'Parece que no tienes permiso para acceder a esta página.',
    button: 'Inicio',
  },
  Error404: {
    title: 'Mmm... Parece que esta página se ha ido',
    description: 'Parece que esta página no se encontró o no existe.',
    button: 'Inicio',
  },
  Error503: {
    title: 'Hmm... ¡Servicio indisponible!',
    description:
      'Nuestros servidores están temporalmente demasiado ocupados. Puede volver a intentarlo en unos minutos y debería normalizarse.',
    button: 'Intentar otra vez',
    infoText: 'Si el problema persiste, puede <a>informarlo aquí.</a>',
  },
  AvatarMenu: {
    signOut: 'Desconectar',
    userProfile: 'Perfil del usuario',
  },
  Dashboard: {
    pageTitle: 'Página de inicio',
    errorPage: 'No hay nada que ver aquí',
  },
  Notification: {
    title: 'Notificaciones',
    allTab: 'Todos',
    unreadTab: 'No leído',
    highPriorityTab: 'Prioridad alta',
    markAllRead: 'Marcar todo como leído',
    markUnread: 'Marcar como no leído',
    markRead: 'Marcar como leído',
    highPriority: 'Alta prioridad',
    noNotifications: 'Aún no hay notificaciones.',
    noUnreadNotifications: 'Aún no hay notificaciones no leídas.',
    noHighPriorityNotifications: 'Aún no hay notificaciones de alta prioridad.',
    noMoreNotifications: 'No hay más notificaciones para mostrar.',
    showMore: 'Mostrar más',
    yesterday: 'Ayer, {value}',
    passwordChanged: '¡Contraseña cambiada con éxito!',
  },
  AppSwitcher: {
    yourApps: 'Tus aplicaciones',
    tools: 'Herramientas',
    reload: 'Recargar',
    error: 'Ocurrió un error y no pudimos cargar el menú.',
  },
  UpdatesPopover: {
    updates: 'ACTUALIZACIONES',
    title: 'Nuevo menú de BEES One',
    description:
      'Ahora puedes navegar con facilidad entre las herramientas desde este menú. Pruébalo.',
    pagination: '1 de 1',
    gotIt: 'Entendido',
  },
  AppSwitcherButton: {
    chooseAnApp: 'Seleccione uno app',
  },
  ApprovalFlow: {
    showApprovalFlowButton: 'Approval flow',
  },
  AppCatalog: {
    showAppCatalogButton: 'Mostrar catálogo de aplicaciones',
  },
  AdminTool: {
    showAdminToolButton: 'Herramienta de administración BEES One',
  },
  TermsAndConditions: {
    showTermsAndConditionsButton: 'Terms & Conditions',
  },
  PrivacyPolicy: {
    showPrivacyPolicyButton: 'Privacy Policy',
  },
  TermsAndPolicies: {
    titleFirstLogin: 'Términos y condiciones y Política de privacidad',
    titleUpdate:
      'Actualización a los Términos y condiciones y la Política de privacidad',
    descriptionParagraph1:
      'Para continuar, confirma que has leído y comprendes los Términos y condiciones y la Política de privacidad de BEES ONE marcando ambas casillas a continuación.',
    descriptionParagraph2:
      'Se puede hacer clic en ambos documentos y estos se muestran en varios idiomas. Puedes volver a consultar los términos en cualquier momento al navegar hasta el menú de configuración.',
    agreeTerms: 'He leído y comprendo los {link} de BEES One.',
    agreePolicies: 'He leído y comprendo la {link} de BEES One.',
    terms: 'Términos y condiciones',
    policies: 'Política de privacidad',
    termsLink: 'Términos y condiciones',
    policiesLink: 'Política de privacidad',
    termsError: 'Debes aceptar los términos para continuar.',
    policiesError: 'Debes aceptar la política para continuar.',
    declineButton: 'Rechazar',
    agreeButton: 'Continuar',
  },
  ...appHeader.es419,
};

export default es419;
