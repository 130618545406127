import { useSegmentAnalytics } from 'hooks/useSegmentAnalytics/useSegmentAnalytics';
import { getUserIds } from 'utils';
import { MenuInteraction } from '../../../analytics';

export const useAnalyticsMenuNavigation = () => {
  const segmentAnalytics = useSegmentAnalytics();

  const track = (appName: string, menuInteraction: MenuInteraction) => {
    const { anonymousId, userId } = getUserIds();
    segmentAnalytics?.menuNavigation({
      anonymousId,
      userId,
      properties: {
        app_name: appName,
        menu_interaction: menuInteraction,
      },
    });
  };

  return { track };
};
